import {
    EnbagoLogo,
    Arrow,
    Articles,
    Ban,
    Notifications,
    Message,
    Statics,
    UsersGroup,
    ArrowType2,
    Logout,
} from 'assets';
import { ButtonIcon, Modal } from 'components';
import { useLocales, useLogout } from 'hooks';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useRoute } from 'react-router5';
import { RouteProps } from 'types';

export const Header = () => {
    const { route } = useRoute();
    const auth = useSelector<any>((state) => state.auth?.entities?.user) as any;
    const [isShowMenuStatic, setIsShowMenuStatic] = useState(false);
    const [isMode, setIsMode] = useState(false);
    const { trans, Trans } = useLocales();
    const [isOpen, setIsOpen] = useState(false);
    const handleLogut = useLogout();

    const docsTItle: any = {
        articles: trans('g.articles'),
        messages: trans('g.messages'),
        notification: trans('g.notification'),
        home: trans('g.home'),
        system: trans('g.system'),
        banlist: trans('g.banlist'),
        about: trans('about.title'),
        terms: trans('terms.title'),
    };

    // for change title tab
    useEffect(() => {
        document.title = `Enbago | ${docsTItle[route.name]}`;
    }, [route]);

    const RoutesList: RouteProps[] = [
        { name: 'articles', icon: <Articles /> },
        { name: 'messages', icon: <Message /> },
        { name: 'notification', icon: <Notifications /> },
        { name: 'home', icon: <UsersGroup /> },
        { name: 'system', icon: <UsersGroup /> },
        { name: 'banlist', icon: <Ban /> },
    ];

    const StaticsPages: RouteProps[] = [
        { name: 'about', icon: null },
        { name: 'terms', icon: null },
    ];

    const menuStatics = () => {
        setIsShowMenuStatic(!isShowMenuStatic);
    };

    const toggleMode = () => {
        setIsMode(!isMode);
    };

    const Li = ({ name, icon, isStatic }: any) => {
        return (
            <li>
                <Link
                    onClick={toggleMode}
                    routeName={name}
                    className={`${name === 'articles' && 'haveNotification'}}`}
                >
                    {icon}
                    <span style={{ paddingRight: isStatic ? '17px' : '' }}>{docsTItle[name]}</span>
                </Link>
            </li>
        );
    };

    return (
        <aside className={isMode ? 'mode' : ''}>
            <div className={`menu_toggle ${isMode ? 'mode' : ''}`}>
                <ButtonIcon onClick={toggleMode} icon={ArrowType2} bg="bg1" size={30} />
            </div>

            <Link className="logo" routeName={'home'}>
                <img src={EnbagoLogo} alt="enbago logo" />
            </Link>

            <div
                className="admin_name fw-bold pb-8"
                style={{ color: '#ffffff', letterSpacing: '0.5px' }}
            >
                <span className="p-0 m-0"> {auth?.name}</span>
                <span className="p-0 m-0" style={{ lineHeight: '0.4' }}>
                    {auth?.email}
                </span>
            </div>

            <ul>
                {RoutesList.map((route) => {
                    if (route.name === 'system') {
                        if (auth?.role === 'SUPER_ADMIN') return <Li key={route.name} {...route} />;
                    } else {
                        return <Li key={route.name} {...route} />;
                    }
                })}

                <li onClick={menuStatics} aria-hidden="true" className="line">
                    <Statics />
                    <span>
                        <Trans i18nKey={'g.static.pages'} />
                    </span>
                    <Arrow
                        className="arrow"
                        style={{ transform: `rotate(${isShowMenuStatic ? 180 : 0}deg)` }}
                    />
                </li>

                {isShowMenuStatic &&
                    StaticsPages.map((route) => {
                        return <Li isStatic key={route.name} {...route} />;
                    })}
            </ul>

            <Modal
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                onClick={handleLogut}
                icon={Logout}
                title="g.logout"
                note="g.pop.logout.note"
                titleSubmit="g.logout"
                typeBtn="red"
                typeSvg="red"
            />

            <button onClick={() => setIsOpen(true)} className="logout">
                <Logout />
                <Trans i18nKey={'g.logout'} />
            </button>
        </aside>
    );
};
