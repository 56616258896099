import { Editor, Input } from 'components';
import { useLocales } from 'hooks';
import { FC } from 'react';

export const TheArticle: FC<any> = ({
    justView,
    titleRegister,
    valueArticle,
    setValueArticle,
    valid,
    error,
    setOriginValue,
}) => {
    const { trans, Trans } = useLocales();

    return (
        <div className="bowl the_article">
            <div className="card-title m-0">
                <h3 className="fw-bolder m-0">
                    <span className="col-lg-4 fw-bold text-muted">
                        <Trans i18nKey={'article.the.article'} />
                    </span>
                </h3>
            </div>
            <div className="title">
                <Input
                    disabled={justView}
                    valid={valid}
                    placeholder={trans('article.article.title')}
                    register={titleRegister}
                    error={error}
                    style={{ width: '50%' }}
                />
            </div>
            <div className="editor">
                <Editor
                    readOnly={justView}
                    activeColors={true}
                    value={valueArticle}
                    setValue={setValueArticle}
                    setOriginValue={setOriginValue}
                />
            </div>
        </div>
    );
};
