import { createSlice } from '@reduxjs/toolkit';
import { requestAsyncThunk, responseAsyncThunk } from 'services/templates';
import { UsersStateProps } from 'types';

const initialState = {
    loading: 'idle',
    entities: {},
    currentRequestId: undefined,
    error: [],
} as UsersStateProps;

// get list of users
export const getListOfRooms = () => {
    return requestAsyncThunk({
        storeName: 'getListOfRooms',
        _url: `user/admin/list-of-rooms?`,
        method: 'GET',
    });
};

export const getListOfRoomsSLice = createSlice({
    name: 'getListOfRooms',
    initialState,
    reducers: { resetAction: () => initialState },
    extraReducers: responseAsyncThunk(getListOfRooms()),
});
