import styled from 'styled-components';

const Container = styled.section`
    position: relative;
    height: 100vh;
`;

const Section = styled.section`
    width: 740px;
    background-color: #fff;
    margin: 20px;
    padding: 15px;
    @media only screen and (max-width: 800px) {
        width: 90%;
    }
`;

const Edit = styled.div`
    height: 400px;
    margin-top: 20px;
`;

const Save = styled.div`
    background-color: #fff;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 60px;
    display: flex;
    align-items: center;
    padding: 0 50px;
    justify-content: flex-end;
    right: 0;
`;

export { Container, Section, Edit, Save };
