import { BlockAction } from 'assets';
import { Modal, Toggle } from 'components';
import { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { articlesAPI } from 'services/apis';
import { SUCCESS_STATUS } from 'constants/auth';

export const ToggleIsPin: FC<any> = ({ row }) => {
    const { isFixed, id } = row.original;
    const { register, watch, reset } = useForm<any>({ defaultValues: { isFixed } });
    const [isOpen, setIsOpen] = useState('');
    const dispatch = useDispatch<any>();

    useEffect(() => {
        const subscription = watch((value) => {
            handlePinPost(value);
        });
        return () => subscription.unsubscribe();
    }, []);

    const handlePinPost = async ({ isFixed }: any) => {
        const { payload } = await dispatch(articlesAPI.sendPinPost()({ isFixed, postId: id }));
        if (!SUCCESS_STATUS.includes(payload?.status)) {
            setIsOpen('true');
            reset({ isFixed: false });
        }
    };

    return (
        <div className="pinarticle">
            <Toggle name="pinpost" register={register('isFixed')} />
            <Modal
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                onClick={() => setIsOpen('')}
                icon={BlockAction}
                title="g.pop.articlepin"
                note="g.pop.articlepin.note"
                titleSubmit="g.oky"
                typeSvg="red"
                single
            />
        </div>
    );
};
