import { Toggle } from 'components';
import { FC } from 'react';

export const BoxOption: FC<any> = ({
    justView,
    register,
    title,
    note,
    children,
    name,
    onClick,
}) => {
    return (
        <div className="bowl pin_article">
            <div className="hide">
                <h3 className="col-lg-4 text-muted text-nowrap">{title}</h3>
                <Toggle disabled={justView} onClick={onClick} name={name} register={register} />
            </div>
            <div className="note">
                <span>{note}</span>
            </div>
            <div>{children}</div>
        </div>
    );
};
