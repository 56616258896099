import styled from 'styled-components';

const SvgIcon = styled.div`
    width: 150px;
    height: 150px;
    border-radius: 50%;
    display: grid;
    place-content: center;

    &.red,
    &.single {
        background-color: #e528321d;
    }

    &.reject {
        border-radius: 12px;
        svg {
            width: 130px;
            height: 130px;
        }
    }

    svg {
        width: 80px;
        height: 80px;
        path {
            fill: #e52833;
        }
    }

    &.blue_save svg path {
        fill: #0fa7e4;
    }

    &.none {
        svg {
            width: 100px;
            height: 100px;
        }
        background-color: transparent;
    }
`;

const Title = styled.h1`
    color: #181c32;
`;

const Notes = styled.span`
    color: #848692;
    font-size: 1.1rem;
`;

const Btns = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-around;
    gap: 20px;
`;

export { SvgIcon, Title, Notes, Btns };
