import { createSlice } from '@reduxjs/toolkit';
import { requestAsyncThunk, responseAsyncThunk } from 'services/templates';
import { UsersStateProps } from 'types';

const initialState = {
    loading: 'idle',
    entities: {},
    currentRequestId: undefined,
    error: [],
} as UsersStateProps;

// get content pinned pages by type
export const getContentPinned = () => {
    return requestAsyncThunk({
        storeName: 'getContentPinned',
        _url: `user/admin/pinned-page?`,
        method: 'GET',
    });
};

export const getContentPinnedSLice = createSlice({
    name: 'getContentPinned',
    initialState,
    reducers: { resetAction: () => initialState },
    extraReducers: responseAsyncThunk(getContentPinned()),
});

// update content pinned page by type
export const updateContentPinned = () => {
    return requestAsyncThunk({
        storeName: 'updateContentPinned',
        _url: `user/admin/pinned-page`,
        method: 'PUT',
    });
};

export const updateContentPinnedSLice = createSlice({
    name: 'updateContentPinned',
    initialState,
    reducers: { resetAction: () => initialState },
    extraReducers: responseAsyncThunk(updateContentPinned()),
});
