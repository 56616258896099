import { ButtonIcon, FormAddAdmin } from 'components';
import { useState } from 'react';
import { EditIcon } from 'assets';

export const ModalEditAdmin = ({ row }: any) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <FormAddAdmin isAdd={true} isOpen={isOpen} setIsOpen={setIsOpen} row={row.original} />
            <ButtonIcon onClick={() => setIsOpen(true)} icon={EditIcon} size={40} bg="bg1" />
        </>
    );
};
