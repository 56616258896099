import { useDispatch } from 'react-redux/es/hooks/useDispatch';
import { router } from 'routers';
import { authAPI } from 'services/apis';

export const useLogout = () => {
    const dispatch = useDispatch<any>();

    const handleLogut = () => {
        dispatch(authAPI.authSlice.actions.resetAction());
        router.navigate('login', {}, { replace: true });
    };

    return handleLogut;
};
