import styled from 'styled-components';

export const TextEditorContainer = styled.div`
    height: 560px;

    .quill {
        display: flex;
        flex-direction: column;
    }
    .ql-active,
    .ql-picker-options {
        color: black !important;
    }
    button.ql-active .ql-stroke {
        stroke: black !important;
    }
    .ql-snow .ql-picker .ql-picker-label {
        color: black !important;
    }
    .ql-toolbar .ql-stroke {
        fill: none;
        stroke: #d6d6e0 !important;
    }
    .ql-toolbar .ql-fill {
        fill: #dadada !important;
        stroke: none;
    }
    .ql-toolbar .ql-picker {
        color: #d6d6e0 !important;
    }
    .ql-toolbar.ql-snow,
    .ql-container.ql-snow {
        border: 1px solid #e4e6ee;
    }
    .ql-clipboard:not(.ql-hidden) {
        position: absolute;
        left: -1000px;
        top: -1000px;
        visibility: hidden;
    }
    button.ql-active .ql-fill {
        stroke: black !important;
    }
`;
