import styled from 'styled-components';

const Container = styled.div`
    background-color: rgb(255, 255, 255);
    height: 100px;
    padding: 20px;
    box-shadow: 0 0.1px 3px rgba($color: #000, $alpha: 0.05);
    display: flex;
    justify-content: space-between;

    span {
        color: #969696;
        font-size: 0.9rem;
    }

    .last {
        color: #000;
    }

    @media only screen and (max-width: 1000px) {
        padding-right: 60px;
    }
`;

export { Container };
