import styled from 'styled-components';

const Form = styled.div`
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const Section = styled.div`
    width: 300px;
    &.active {
        display: flex;
        width: 450px;
        align-items: center;
    }
`;

const BowlInput = styled.div`
    background-color: #fff;
    display: flex;
    align-items: center;
    width: 100%;
    height: 45px;
    border: 1px solid #e1e3e697;
    border-radius: 8px;
    overflow: hidden;

    &.isValid {
        border: 1px solid #f00 !important;
    }

    &:hover {
        border: 1px solid #bfc0c2c7;
    }
`;

const InputTag = styled.input`
    width: 100%;
    height: 100%;
    outline: none;
    font-weight: bold;

    &::placeholder {
        color: #a1a5b7;
    }
`;

const BowlSvg = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 100%;
`;

export { Section, Form, BowlInput, BowlSvg, InputTag };
