import { createSlice } from '@reduxjs/toolkit';
import { requestAsyncThunk, responseAsyncThunk } from 'services/templates';
import { UsersStateProps } from 'types';

const initialState = {
    loading: 'idle',
    entities: {},
    currentRequestId: undefined,
    error: [],
} as UsersStateProps;

// get list of admins
export const sendNotification = () => {
    return requestAsyncThunk({
        storeName: 'sendNotification',
        _url: `newsletter/dashboard/notification`,
        method: 'POST',
    });
};

export const sendNotificationSLice = createSlice({
    name: 'sendNotification',
    initialState,
    reducers: {
        resetAction: () => initialState,
        setNotificationArticle: (state, payload: any) => {
            state.entities.article = payload;
            return state;
        },
    },
    extraReducers: responseAsyncThunk(sendNotification()),
});
