import { FC } from 'react';
import { useLocales } from 'hooks';
import { Radio } from 'components/radio';
import { Option, TypeSound } from './theme';
import { NotificationSoundProps } from 'types';

export const NotificationSound: FC<NotificationSoundProps> = ({ register }) => {
    const { Trans } = useLocales();

    return (
        <>
            <TypeSound>
                <h3>
                    <Trans i18nKey={'article.editpost.option.notification.reindeer'} />
                </h3>
            </TypeSound>
            <Option>
                <Radio value="DEFAULT" label="Default" register={register} />
            </Option>
            <Option>
                <Radio value="SILENT" label="Silent" register={register} />
            </Option>
            <Option>
                <Radio
                    value="SILENT_WITH_VIBRATION"
                    label="silent with vibration"
                    register={register}
                />
            </Option>
            <Option>
                <Radio value="ENBAGO_ALERT" label="Enbago Alert" register={register} />
            </Option>
        </>
    );
};
