import { combineReducers } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import localforage from 'localforage';

import {
    authAPI,
    articlesAPI,
    usersAPI,
    adminsAPI,
    notificationAPI,
    pinnedPageAPI,
    messageAPI,
    mediaAPI,
    postAPI,
} from 'services/apis';

const authPersistConfig = {
    key: 'auth',
    storage: localforage,
    whiteList: ['entities'],
};

const sendNotificationPersistConfig = {
    key: 'sendNotification',
    storage: localforage,
    whiteList: ['entities'],
};

const authPersistReducer = persistReducer(authPersistConfig, authAPI.authSlice.reducer);
const sendNotificationPersistReducer = persistReducer(
    sendNotificationPersistConfig,
    notificationAPI.sendNotificationSLice.reducer,
);

const reducers = combineReducers({
    auth: authPersistReducer,
    // articlesAPI
    getListArticles: articlesAPI.getListArticlesSlice.reducer,
    getArticleById: articlesAPI.getArticleByIdSlice.reducer,
    sendApprovePost: articlesAPI.sendApprovePostSlice.reducer,
    rejectArticle: articlesAPI.rejectArticleSlice.reducer,
    getComments: articlesAPI.getCommentsSlice.reducer,
    deleteComment: articlesAPI.deleteCommentSlice.reducer,
    sendPinPost: articlesAPI.sendPinPostSlice.reducer,
    checkIsFixed: articlesAPI.checkIsFixedSlice.reducer,
    checkIsStory: articlesAPI.checkIsStorySlice.reducer,
    deactivateArticle: articlesAPI.deactivateArticleSlice.reducer,
    updatePost: articlesAPI.updatePostSlice.reducer,
    deleteReplay: articlesAPI.deleteReplaySlice.reducer,

    // usersAPI
    getListUsers: usersAPI.getListUsersSLice.reducer,
    putBlockUser: usersAPI.putBlockUserSlice.reducer,
    blockedUserList: usersAPI.blockedUserListSlice.reducer,

    // admins
    getListAdmins: adminsAPI.getListAdminsSLice.reducer,
    addAdmin: adminsAPI.addAdminSLice.reducer,
    updateAdmin: adminsAPI.updateAdminSLice.reducer,
    blockAdmin: adminsAPI.blockAdminSLice.reducer,
    // pinned pages
    getContentPinned: pinnedPageAPI.getContentPinnedSLice.reducer,
    updateContentPinned: pinnedPageAPI.updateContentPinnedSLice.reducer,
    // notifications
    sendNotification: sendNotificationPersistReducer,
    refreshToken: authAPI.refreshTokenSlice.reducer,

    // messageAPI
    getListOfRooms: messageAPI.getListOfRoomsSLice.reducer,

    // media
    removeMedia: mediaAPI.removeMediaSLice.reducer,
    uploadSocketMedia: mediaAPI.uploadSocketMediaSLice.reducer,

    // postApi
    getSinglePost: postAPI.getSinglePostSlice.reducer,
});

export default reducers;
