import { useLocales } from 'hooks';
import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { mediaAPI } from 'services/apis';
import { toast } from 'react-toastify';
import { SUCCESS_STATUS } from 'constants/auth';
import { TextareaSender } from '../theme';

export const MessageSender: FC<any> = ({ setMessages, sendMessage, currentUser, socket }) => {
    const [message, setMessage] = useState<string>('');
    const dispatch = useDispatch<any>();
    const { trans, Trans } = useLocales();

    const sendMessageRoom = async () => {
        if (message) {
            const messageData = { type: 'RESPONSE', content: message?.trim() };
            setMessages((prevMessages: any) => [...prevMessages, messageData]);
            sendMessage('send_message', messageData);
            setMessage('');
        }
    };

    const handleUploadMedia = async (file: any) => {
        console.log(file);
        console.log(file[0], file, 'filefilefilefilefile');
        const formData = new FormData();
        formData.append('type', 'RESPONSE');
        formData.append('content', file[0]);
        formData.append('userId', currentUser?.userId);
        const { payload } = await dispatch(mediaAPI.uploadSocketMedia()(formData));
        console.log('payload: ', payload);

        if (!SUCCESS_STATUS.includes(payload?.status)) {
            const msg = payload?.message?.message?.trim().split(' ').join('.').toLowerCase();
            console.log('msg: ', msg);
            toast.error(`${trans(msg)}`);
        } else {
            console.log('payload.data.content: ', payload?.data?.content);
            const messageData = {
                type: 'RESPONSE',
                content: payload?.data?.content,
                contentType: file[0].type.split('/')[0].toUpperCase(),
            };
            console.log(file[0].type.split('/')[0].toUpperCase());

            setMessages((prevMessages: any) => [...prevMessages, messageData]);
            // sendMessage('send_message', messageData);
        }
    };

    return (
        <TextareaSender className="card-footer pt-4" id={'kt_chat_messenger_footer'}>
            <textarea
                className="form-control form-control-flush mb-3"
                rows={1}
                data-kt-element="input"
                placeholder={trans('message.send.textarea.plch')}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
            ></textarea>

            <div className="d-flex flex-stack">
                <div className="d-flex align-items-center me-2">
                    <label htmlFor="file-msg-1" className="message_link">
                        <input
                            onChange={(e) => handleUploadMedia(e.target.files)}
                            id="file-msg-1"
                            type="file"
                            accept="image/*, video/*"
                            style={{ display: 'none' }}
                            onClick={(event: any) => {
                                event.target.value = null;
                            }}
                        />
                        <i className="bi bi-paperclip fs-3"></i>
                    </label>
                </div>
                <button
                    className="btn btn-primary"
                    type="button"
                    data-kt-element="send"
                    onClick={sendMessageRoom}
                >
                    <Trans i18nKey={'message.send.btn.send'} />
                </button>
            </div>
        </TextareaSender>
    );
};
