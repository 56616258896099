import { FC, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { SUCCESS_STATUS } from 'constants/auth';
import { useDispatch, useSelector } from 'react-redux';
import { adminsAPI } from 'services/apis';
import { useLocales } from 'hooks';
import { Box, Btns, FieldAdmin, H2, Label } from './theme';
import { Input } from 'components/input';
import { FormAddAdminProps } from 'types';
import { Modal as ModalTag } from 'react-bootstrap';
import { Button } from 'components/button';
import { Modal } from 'components/modal';
import { BlockUser } from 'assets';

export const FormAddAdmin: FC<FormAddAdminProps> = ({ setIsOpen, isOpen, row, isAdd }) => {
    const dispatch = useDispatch<any>();
    const [isConfirm, setIsConfirm] = useState(false);
    const { trans, Trans } = useLocales();
    const {
        register,
        handleSubmit,
        reset,
        watch,
        formState: { errors },
    } = useForm<any>();

    useEffect(() => {
        if (row?.id) {
            const { name, email } = row;
            reset({ name, email });
        }
    }, [row]);

    const isLoading = useSelector<any>((state: any) => state.addAdmin?.loading === 'pending');
    const isLoadingSave = useSelector<any>(
        (state: any) => state.updateAdmin?.loading === 'pending',
    );

    const handleAddAdmin = handleSubmit(async (data: any) => {
        const handleIs = row
            ? adminsAPI.updateAdmin()({ adminId: row.id, name: data.name, email: data.email })
            : adminsAPI.addAdmin()({ ...data, role: 'ADMIN' });
        const { payload } = await dispatch(handleIs);

        if (SUCCESS_STATUS.includes(payload?.status)) {
            toast.success(
                `${trans('toast.admin.add.start')} ${data.name} ${trans('toast.admin.add.end')}`,
            );
        } else {
            const defaultValue = payload?.message?.message;
            toast.error(`${trans('toast.system.email.used', { defaultValue })}`);
        }
        setIsOpen(false);
    });

    const handleConfirm = () => {
        setIsConfirm(false);
        handleAddAdmin();
    };
    return (
        <>
            {/* when cancel page */}
            <Modal
                isOpen={isConfirm}
                setIsOpen={setIsConfirm}
                onClick={handleConfirm}
                icon={BlockUser}
                note={isAdd ? 'system.modal.edit.confirem' : 'system.modal.add.confirem'}
                titleSubmit="g.yes"
                titleCancel="g.no"
                typeSvg="blue_save"
            />

            <ModalTag
                onHide={() => setIsOpen('')}
                show={isOpen}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <ModalTag.Body className="modal_content">
                    <Box>
                        <H2>
                            <Trans
                                i18nKey={
                                    isAdd ? 'system.modal.edit.admin' : 'system.modal.add.admin'
                                }
                            />
                        </H2>
                        <FieldAdmin>
                            <Label>
                                <Trans i18nKey={'system.modal.add.fullname'} /> :{' '}
                                <span className="star"> *</span>
                            </Label>
                            <Input
                                style={{ width: '240px' }}
                                valid={errors?.name ? true : false}
                                register={register('name', { required: true })}
                                placeholder={trans('system.modal.add.plch.admin.name')}
                                error={errors?.name ? trans('g.input.required') : ''}
                            />
                        </FieldAdmin>
                        <FieldAdmin>
                            <Label>
                                <Trans i18nKey={'system.modal.add.email'} /> :{' '}
                                <span className="star"> *</span>
                            </Label>
                            <Input
                                style={{ width: '240px' }}
                                valid={errors?.email ? true : false}
                                type="email"
                                name="email"
                                register={register('email', {
                                    required: true,
                                    pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                })}
                                placeholder={trans('system.modal.add.plch.email')}
                                error={errors?.email ? trans('g.input.required') : ''}
                            />
                        </FieldAdmin>
                        <FieldAdmin>
                            <Label>
                                <Trans i18nKey={'system.modal.add.password'} /> :{' '}
                                <span className="star"> *</span>
                            </Label>
                            <Input
                                isEye={watch('password')}
                                style={{ width: '240px' }}
                                type="password"
                                valid={errors?.password ? true : false}
                                register={register('password', { required: !isAdd })}
                                error={errors?.password ? trans('g.input.required') : ''}
                                placeholder={trans('system.modal.add.plch.password')}
                            />
                        </FieldAdmin>
                        <Btns>
                            <Button
                                style={{ width: '44%' }}
                                onClick={(e: any) => {
                                    e.preventDefault();
                                    setIsConfirm(true);
                                }}
                                isLoading={isLoading || isLoadingSave ? true : false}
                                title={trans(
                                    isAdd ? 'g.changes.save' : 'system.modal.add.btn.submit',
                                )}
                            />
                            <Button
                                style={{ width: '44%' }}
                                onClick={() => setIsOpen('')}
                                title="الغاء"
                                classType="secondary"
                            />
                        </Btns>
                    </Box>
                </ModalTag.Body>
            </ModalTag>
        </>
    );
};
