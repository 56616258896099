import { HandleErrors } from 'components/handleErrors';
import { FC } from 'react';
import { TextareaProps } from 'types';
import { AreaText, TextareaTag, Container } from './theme';

export const Textarea: FC<TextareaProps> = ({ register, placeholder, error, style }) => {
    return (
        <Container>
            <AreaText style={style}>
                <TextareaTag {...register} placeholder={placeholder}></TextareaTag>
            </AreaText>
            {error && <HandleErrors error={error} />}
        </Container>
    );
};
