import { createSlice } from '@reduxjs/toolkit';
import { requestAsyncThunk, responseAsyncThunk } from '../../templates';

interface tokens {
    tokens: { access: any; refresh: any };
}

interface UsersState {
    entities: tokens | any;
    loading: 'idle' | 'pending' | 'succeeded' | 'failed';
    currentRequestId: undefined;
    error: [];
}

const initialState = {
    loading: 'idle',
    entities: {},
    currentRequestId: undefined,
    error: [],
} as UsersState;

export const signIn = () => {
    return requestAsyncThunk({
        storeName: 'auth',
        _url: `auth/login`,
        method: 'POST',
    });
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        resetAction: () => {
            return initialState;
        },
        setAccessToken: (state, { payload: { accessToken } }: any) => {
            state.entities.tokens = accessToken;
            return state;
        },
    },
    extraReducers: responseAsyncThunk(signIn()),
});

export const refreshToken = () => {
    return requestAsyncThunk({
        storeName: 'refreshToken',
        _url: `/auth/refresh-tokens`,
        method: 'POST',
    });
};

export const refreshTokenSlice = createSlice({
    name: 'refreshToken',
    initialState,
    reducers: {
        resetAction: () => {
            return initialState;
        },
    },
    extraReducers: responseAsyncThunk(refreshToken()),
});
