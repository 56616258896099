import { FC } from 'react';
import { ToggleProps } from 'types';

export const Toggle: FC<ToggleProps> = ({ register, name, onClick, disabled }) => {
    return (
        <div className="menu-item  toggle">
            <div className="menu-content ">
                <label className="form-check form-switch form-check-custom">
                    <input
                        disabled={disabled}
                        className="form-check-input w-50px h-30px"
                        style={{ transform: 'rotate(180deg) scale(0.8)' }}
                        type="checkbox"
                        name={name}
                        {...register}
                        onClick={onClick}
                    />
                </label>
            </div>
        </div>
    );
};
