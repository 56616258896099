/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import { FilesDemo } from './FilesDemo';
import { useLocales } from 'hooks';
import { FileUpload } from 'assets';
import { useDropzone } from 'react-dropzone';
import { FC, useMemo, useState } from 'react';

export const ArticleMedia: FC<any> = ({ onChangeFiles, data, justView, handleRemoveFile }) => {
    const { Trans } = useLocales();
    const { getRootProps, getInputProps } = useDropzone({ onDrop: onChangeFiles });
    const [isShowMore, setIsShowMore] = useState(false);

    const listView: any[] = useMemo(() => {
        let limit: number = isShowMore ? 50 : 12;
        return data?.slice(0, limit);
    }, [data, isShowMore]);

    return (
        <div className="bowl media">
            <div className="card-title m-0">
                <h3 className="fw-bolder m-0">
                    <span className="col-lg-4 fw-bold text-muted">
                        <Trans i18nKey={'article.media'} />
                    </span>
                </h3>
            </div>

            <div className="uploads_files">
                <label
                    role="button"
                    {...getRootProps()}
                    onClick={(e) => e.stopPropagation()}
                    htmlFor="files-uploads"
                >
                    <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed p-6">
                        <FileUpload className="svg-icon-2tx svg-icon-primary me-4" />
                        <div className="mb-3 mb-md-0 fw-bold me-4">
                            <h4 className="text-gray-800 fw-bolder">
                                <Trans i18nKey={'article.media.titleupload'} />
                            </h4>
                            <div className="fs-7 text-gray-600 pe-7">
                                <Trans i18nKey={'article.media.noteupload'} />
                            </div>
                        </div>
                    </div>
                    <input
                        {...getInputProps()}
                        onChange={(e) => onChangeFiles(e.target.files)}
                        id="files-uploads"
                        type="file"
                        onClick={(event: any) => {
                            event.target.value = null;
                        }}
                        disabled={justView}
                        accept=".mp4, .jpg, .jpeg, .png, .svg, .heif, .tiff, .jfif, .gif, .bmp, .avi, .hevc, .mov, .h264, .H265, .264, .265, .mts, .m2ts, .sgi, .tag, .bmb, .mxf"
                    />
                </label>

                <FilesDemo
                    justView={justView}
                    isShowMore={isShowMore}
                    clickMore={() => setIsShowMore(true)}
                    handleRemoveFile={handleRemoveFile}
                    data={listView}
                />
            </div>
        </div>
    );
};
