import React, { useEffect, useMemo, useState } from 'react';
import { usePagination, useSortBy, useTable } from 'react-table';
import { TableProps } from 'types';
import { Pagination } from 'components/pagination';
import { SearchField } from 'components/searchField';
import { Loader } from 'components/loader';
import { useSearch } from 'hooks/useSearch';
import { ArticlesTabs } from './articlesTabs';
import { TableHeader } from './tableHeader';
import { TableBody } from './tableBody';

// just with table articles
const removeColumns: any = {
    none: [],
    APPROVED: [],
    PENDING: [
        'rejectAt',
        'shares',
        'reacts',
        'comments',
        'pinnedArticle',
        'email',
        'title',
        'isStory',
        'notification',
    ],
    REJECTED: ['pinnedArticle', 'email', 'notification'],
    ARCHIVED: ['pinnedArticle', 'email', 'notification'],
};

export const Table: React.FC<TableProps> = ({
    columns,
    data,
    getFetch,
    isLoading,
    error,
    pagination,
    isAdmin,
    pendingCount,
    titleCount,
    currentTab = 'none',
    setCurrentTab,
    msgEmpty,
    reloadData = [],
}) => {
    const [search, setSearch] = useState('');

    const setSearctAction = (event: any) => {
        gotoPage(0);
        setSearch(event?.target.value);
    };

    const { searchAction } = useSearch({ action: setSearctAction });
    const [pageCount, setPageCount] = useState(1);
    const columnsMemo = useMemo(() => columns, [columns]);
    const dataMemo = useMemo(() => data || [], [data]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        setPageSize,
        setHiddenColumns,
        gotoPage,
        state: { pageIndex, pageSize, sortBy },
    }: any = useTable(
        {
            columns: columnsMemo,
            data: dataMemo,
            manualSortBy: true,
            autoResetSortBy: true,
            manualPagination: true,
            autoResetFilters: false,
            autoResetPage: false,
            manualFilters: true,
            pageCount,
            initialState: {
                hiddenColumns: removeColumns[currentTab],
                pageIndex: 0,
                pageSize: 10,
            },
        } as any,
        useSortBy,
        usePagination,
    );

    useEffect(() => {
        setPageCount(Math.ceil(pagination?.count / pageSize));
    }, [pageSize, pagination?.count]);

    useEffect(() => {
        const { desc, id }: any = sortBy.length ? sortBy[0] : {};
        const sort: any = {};
        id && (sort[id] = desc ? 'desc' : 'asc');

        getFetch({ pageIndex, pageSize, search, sort });
    }, [pageIndex, pageSize, search, currentTab, sortBy, ...reloadData]);

    useEffect(() => {
        if (currentTab) {
            setHiddenColumns(removeColumns[currentTab]);
        }
    }, [currentTab]);

    const handlePageClick = (event: any) => {
        gotoPage(event.selected);
    };

    return (
        <div style={{ overflowX: 'scroll' }} className="table_container">
            {/* just for articles apge */}
            {currentTab !== 'none' && (
                <ArticlesTabs
                    pendingCount={pendingCount}
                    setCurrentTab={setCurrentTab}
                    gotoPage={gotoPage}
                    currentTab={currentTab}
                />
            )}

            {/* search compo */}
            <SearchField
                searchAction={searchAction}
                name={titleCount}
                counts={pagination?.count?.toLocaleString()}
                isAdmin={isAdmin}
            />

            {/* the table */}
            <table {...getTableProps()} className="table align-middle gs-0 gy-5">
                <TableHeader headerGroups={headerGroups} />
                {rows.length ? (
                    <TableBody
                        getTableBodyProps={getTableBodyProps}
                        prepareRow={prepareRow}
                        rows={rows}
                    />
                ) : null}
            </table>

            {!rows.length && !isLoading && (
                <div className="empty_list">
                    <div>{msgEmpty}</div>
                    <div>{error}</div>
                </div>
            )}

            {/* loader  */}
            {isLoading && !rows.length && <Loader />}

            {/* dont show until there data*/}
            {!isLoading && rows.length ? (
                <Pagination
                    onChangePageSize={(event: any) => setPageSize(Number(event.target.value))}
                    handlePageClick={handlePageClick}
                    // initialPage={pagination?.current_page - 1}
                    initialPage={pageIndex}
                    pageCount={pageCount}
                    pagination={pagination}
                />
            ) : null}
        </div>
    );
};
