import { createSlice } from '@reduxjs/toolkit';
import { requestAsyncThunk, responseAsyncThunk } from 'services/templates';
import { UsersStateProps } from 'types';

const initialState = {
    loading: 'idle',
    entities: {},
    currentRequestId: undefined,
    error: [],
} as UsersStateProps;

// get list of users
export const getListUsers = () => {
    return requestAsyncThunk({
        storeName: 'getListUsers',
        _url: `user/member/usersList?`,
        method: 'POST',
    });
};

export const getListUsersSLice = createSlice({
    name: 'getListUsers',
    initialState,
    reducers: { resetAction: () => initialState },
    extraReducers: responseAsyncThunk(getListUsers()),
});

// block user
export const putBlockUser = () => {
    return requestAsyncThunk({
        storeName: 'putBlockUser',
        _url: `user/member/block`,
        method: 'PUT',
    });
};

export const putBlockUserSlice = createSlice({
    name: 'putBlockUser',
    initialState,
    reducers: { resetAction: () => initialState },
    extraReducers: responseAsyncThunk(putBlockUser()),
});

// block user
export const blockedUserList = () => {
    return requestAsyncThunk({
        storeName: 'blockedUserList',
        _url: `user/admin/block?`,
        method: 'POST',
    });
};

export const blockedUserListSlice = createSlice({
    name: 'blockedUserList',
    initialState,
    reducers: { resetAction: () => initialState },
    extraReducers: responseAsyncThunk(blockedUserList()),
});
