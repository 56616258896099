import { BlockUser } from 'assets';
import { ButtonIcon, Modal } from 'components';
import { SUCCESS_STATUS } from 'constants/auth';
import { useLocales } from 'hooks';
import { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'react-router5';
import { toast } from 'react-toastify';
import { usersAPI } from 'services/apis';

export const ModalRemoveBlock: FC<any> = ({ row }) => {
    const [isOpen, setIsOpen] = useState<string>('');
    const { id, name, isBlocked } = row.original;
    const { trans } = useLocales();
    const dispatch = useDispatch<any>();
    const { navigate } = useRouter();

    const isLoadingSave = useSelector<any>(
        (state: any) => state.putBlockUser?.loading === 'pending',
    );

    const removeBlock = async () => {
        const { payload } = await dispatch(
            usersAPI.putBlockUser()({
                userId: id,
                isBlocked: !isBlocked,
            }),
        );
        if (SUCCESS_STATUS.includes(payload?.status)) {
            let title = `${trans('toast.banremove.end')} ${name}  ${trans(
                'toast.banremove.start',
            )}`;
            toast.success(title);
            setIsOpen('');
            navigate('home');
        } else {
            toast.error(payload?.message?.message);
        }
    };

    return (
        <div className="btn_block">
            <ButtonIcon onClick={() => setIsOpen(id)} icon={BlockUser} size={40} bg="bg3" />
            <Modal
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                onClick={removeBlock}
                icon={BlockUser}
                title="g.pop.cancelblock"
                note={`${trans('g.pop.cancelblock.note')} ${name} ؟`}
                titleSubmit="g.pop.cancelblock"
                typeSvg="blue_save"
                isLoading={isLoadingSave ? true : false}
            />
        </div>
    );
};
