import { BlockUser } from 'assets';
import { ButtonIcon, Modal } from 'components';
import { useState } from 'react';
import { adminsAPI } from 'services/apis';
import { toast } from 'react-toastify';
import { SUCCESS_STATUS } from 'constants/auth';
import { useDispatch, useSelector } from 'react-redux';
import { useLocales } from 'hooks';

export const ModalBlockAdmin = ({ row }: any) => {
    const [isOpen, setIsOpen] = useState<string>('');
    const { id, name, isBlocked } = row.original;
    const dispatch = useDispatch<any>();
    const { trans } = useLocales();
    const isLoadingSave = useSelector<any>((state: any) => state.blockAdmin?.loading === 'pending');

    const removeBlock = async () => {
        const { payload } = await dispatch(
            adminsAPI.blockAdmin()({ adminId: id, isBlocked: !isBlocked }),
        );

        if (SUCCESS_STATUS.includes(payload?.status)) {
            const { isBlocked } = payload?.data?.result as any;
            if (isBlocked) {
                toast.success(
                    `${trans('toast.banuser.start')} ${name} ${trans('toast.banuser.end')}`,
                );
            } else {
                toast.success(
                    `${trans('toast.banremove.start')} ${name} ${trans('toast.banremove.end')}`,
                );
            }
        } else {
            toast.error(payload?.message?.message);
        }
        setIsOpen('');
    };

    return (
        <>
            <ButtonIcon
                onClick={() => setIsOpen(id)}
                icon={BlockUser}
                size={40}
                bg={isBlocked ? 'bg3' : 'bg2'}
            />
            <Modal
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                onClick={removeBlock}
                icon={BlockUser}
                title={isBlocked ? 'g.pop.cancelblock' : 'g.pop.ban.admin'}
                note={`${
                    isBlocked ? trans('g.pop.cancelblock.note') : trans('g.pop.ban.note')
                } ${name} ؟  `}
                titleSubmit={isBlocked ? 'g.pop.cancelblock' : 'users.ban'}
                typeBtn={isBlocked ? '' : 'red'}
                typeSvg={isBlocked && 'blue_save'}
                isLoading={isLoadingSave ? true : false}
            />
        </>
    );
};
