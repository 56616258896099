import { createSlice } from '@reduxjs/toolkit';
import { requestAsyncThunk, responseAsyncThunk } from 'services/templates';
import { UsersStateProps } from 'types';

const initialState = {
    loading: 'idle',
    entities: {},
    currentRequestId: undefined,
    error: [],
} as UsersStateProps;

// get list of admins
export const removeMedia = () => {
    return requestAsyncThunk({
        storeName: 'removeMedia',
        _url: `newsletter/dashboard/media`,
        method: 'DELETE',
    });
};

export const removeMediaSLice = createSlice({
    name: 'removeMedia',
    initialState,
    reducers: { resetAction: () => initialState },
    extraReducers: responseAsyncThunk(removeMedia()),
});

// upload files to db
export const uploadSocketMedia = () => {
    return requestAsyncThunk({
        storeName: 'uploadSocketMedia',
        _url: `user/member/socketMedia`,
        method: 'POST',
        exact: 'upload _socket_media',
    });
};

export const uploadSocketMediaSLice = createSlice({
    name: 'uploadSocketMedia',
    initialState,
    reducers: { resetAction: () => initialState },
    extraReducers: responseAsyncThunk(uploadSocketMedia()),
});
