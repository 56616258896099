import { useRoute } from 'react-router5';
import { HeadTitle } from 'components';
import { useLocales } from 'hooks';
import { Container, BoxChat, MsgToSelectRoom, Section } from './theme';
import { ListRooms } from './listRooms';
import { useEffect, useState } from 'react';
import { MessageSender } from './messageSender';
import { HeadChatCompo } from './headChatCompo';
import { ContentChats } from './contentChats';
import { useSocket } from 'hooks/useSocket';
import { useSelector } from 'react-redux';

const SOCKET_API_ENDPOINT = process.env.REACT_APP_URL_STREAMINGURL_STREAMING;

export const MessageManagement = () => {
    const [messages, setMessages] = useState<any[]>([]);
    const auth: any = useSelector<any>((state) => state.auth?.entities);
    const { trans, Trans } = useLocales();
    const { route } = useRoute();
    const { id, profileImage, name, email } = route?.params as any;
    const userParams = {
        id,
        isRead: true,
        updateAt: new Date(),
        userId: route?.params?.id,
        user: { name: decodeURIComponent(name), email, profileImage },
    };

    const [currentUser, setCurrentUser] = useState<any>(userParams || {});

    const handleSelectRoom = (item: any) => {
        if (item?.userId === currentUser?.userId) return;
        setCurrentUser(item);
        setMessages([]);
    };

    console.log(SOCKET_API_ENDPOINT, 'SOCKET_API_ENDPOINTSOCKET_API_ENDPOINTSOCKET_API_ENDPOINT');
    const { socket, sendMessage, onMessage }: any = useSocket({
        url: SOCKET_API_ENDPOINT,
        query: {
            token: auth?.tokens?.access?.token,
            userId: currentUser?.userId,
        },
        path: '/socket/',
        setMessages,
    });

    useEffect(() => {
        if (socket) {
            onMessage('receive_message', (data: any) => {
                setMessages((prevMessages) => [...prevMessages, data]);
            });
        }
    }, [socket]);

    return (
        <Section>
            <HeadTitle title={trans('message.title')} sub={trans('message.title')} />
            <Container className="d-flex flex-column flex-lg-row">
                {/* users for chats */}
                <ListRooms
                    userParams={userParams}
                    handleSelectRoom={handleSelectRoom}
                    currentUser={currentUser}
                />

                {/* conent chat */}
                {currentUser?.id ? (
                    <BoxChat className="card " id="kt_chat_messenger">
                        <HeadChatCompo currentUser={currentUser} />
                        <ContentChats messages={messages} currentUser={currentUser} />
                        <MessageSender
                            sendMessage={sendMessage}
                            currentUser={currentUser}
                            setMessages={setMessages}
                            socket={socket}
                        />
                    </BoxChat>
                ) : (
                    <MsgToSelectRoom>
                        <Trans i18nKey="message.to.select.room" />
                    </MsgToSelectRoom>
                )}
            </Container>
        </Section>
    );
};
