import styled from 'styled-components';

const ButtonTag = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    border: none;
    background-color: transparent;
    border-radius: 6px;
    width: 40px;
    height: 40px;

    &.bg1 {
        background-color: #f1f8ff;
        &:hover {
            background-color: #e6f1ff;
        }
    }

    &.bg2 {
        background-color: #fff1f2;
        &:hover {
            background-color: #ffe7e8;
        }
    }

    &.bg3 {
        background-color: #e52833;
        svg {
            path {
                fill: #fff;
            }
        }
        &:hover {
            background-color: #e52832d1;
        }
    }
`;

export { ButtonTag };
