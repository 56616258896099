import { createSlice } from '@reduxjs/toolkit';
import { requestAsyncThunk, responseAsyncThunk } from 'services/templates';
import { UsersStateProps } from 'types';

const initialState = {
    loading: 'idle',
    entities: {},
    currentRequestId: undefined,
    error: [],
} as UsersStateProps;

// getListArticles
export const getListArticles = () => {
    return requestAsyncThunk({
        storeName: 'getListArticles',
        _url: `newsletter/dashboard/list?`,
        method: 'POST',
    });
};

export const getListArticlesSlice = createSlice({
    name: 'getListArticles',
    initialState,
    reducers: { resetAction: () => initialState },
    extraReducers: responseAsyncThunk(getListArticles()),
});

// getArticleById
export const getArticleById = () => {
    return requestAsyncThunk({
        storeName: 'getArticleById',
        _url: `newsletter/dashboard?`,
        method: 'GET',
    });
};

export const getArticleByIdSlice = createSlice({
    name: 'getArticleById',
    initialState,
    reducers: { resetAction: () => initialState },
    extraReducers: responseAsyncThunk(getArticleById()),
});

// send to approve article
export const updatePost = () => {
    return requestAsyncThunk({
        storeName: 'updatePost',
        _url: `newsletter/dashboard`,
        method: 'PUT',
    });
};

export const updatePostSlice = createSlice({
    name: 'updatePost',
    initialState,
    reducers: { resetAction: () => initialState },
    extraReducers: responseAsyncThunk(updatePost()),
});

// send to approve article
export const sendApprovePost = () => {
    return requestAsyncThunk({
        storeName: 'sendApprovePost',
        _url: `newsletter/dashboard/approve`,
        method: 'POST',
    });
};

export const sendApprovePostSlice = createSlice({
    name: 'sendApprovePost',
    initialState,
    reducers: { resetAction: () => initialState },
    extraReducers: responseAsyncThunk(sendApprovePost()),
});

// send to reject article
export const rejectArticle = () => {
    return requestAsyncThunk({
        storeName: 'rejectArticle',
        _url: `newsletter/dashboard/reject`,
        method: 'POST',
    });
};

export const rejectArticleSlice = createSlice({
    name: 'rejectArticle',
    initialState,
    reducers: { resetAction: () => initialState },
    extraReducers: responseAsyncThunk(rejectArticle()),
});

// send to reject article
export const deactivateArticle = () => {
    return requestAsyncThunk({
        storeName: 'deactivateArticle',
        _url: `newsletter/dashboard/deactivate`,
        method: 'POST',
    });
};

export const deactivateArticleSlice = createSlice({
    name: 'deactivateArticle',
    initialState,
    reducers: { resetAction: () => initialState },
    extraReducers: responseAsyncThunk(deactivateArticle()),
});

// get comments of article
export const getComments = () => {
    return requestAsyncThunk({
        storeName: 'getComments',
        _url: `newsletter/post/`,
        method: 'GET',
    });
};

export const getCommentsSlice = createSlice({
    name: 'getComments',
    initialState,
    reducers: { resetAction: () => initialState },
    extraReducers: responseAsyncThunk(getComments()),
});

// delete comment by id
export const deleteComment = () => {
    return requestAsyncThunk({
        storeName: 'deleteComment',
        _url: `newsletter/post/comment`,
        method: 'DELETE',
    });
};

export const deleteCommentSlice = createSlice({
    name: 'deleteComment',
    initialState,
    reducers: { resetAction: () => initialState },
    extraReducers: responseAsyncThunk(deleteComment()),
});

// delete comment by id
export const deleteReplay = () => {
    return requestAsyncThunk({
        storeName: 'deleteReplay',
        _url: `newsletter/post/reply-comment`,
        method: 'DELETE',
    });
};

export const deleteReplaySlice = createSlice({
    name: 'deleteReplay',
    initialState,
    reducers: { resetAction: () => initialState },
    extraReducers: responseAsyncThunk(deleteReplay()),
});

// send to pin article
export const sendPinPost = () => {
    return requestAsyncThunk({
        storeName: 'sendPinPost',
        _url: `newsletter/dashboard/pin`,
        method: 'PUT',
    });
};

export const sendPinPostSlice = createSlice({
    name: 'sendPinPost',
    initialState,
    reducers: { resetAction: () => initialState },
    extraReducers: responseAsyncThunk(sendPinPost()),
});

// check if article can be a fixed
export const checkIsFixed = () => {
    return requestAsyncThunk({
        storeName: 'checkIsFixed',
        _url: `newsletter/dashboard/pin`,
        method: 'GET',
    });
};

export const checkIsFixedSlice = createSlice({
    name: 'checkIsFixed',
    initialState,
    reducers: { resetAction: () => initialState },
    extraReducers: responseAsyncThunk(checkIsFixed()),
});

// check if article can be a story
export const checkIsStory = () => {
    return requestAsyncThunk({
        storeName: 'checkIsStory',
        _url: `newsletter/dashboard/story`,
        method: 'GET',
    });
};

export const checkIsStorySlice = createSlice({
    name: 'checkIsStory',
    initialState,
    reducers: { resetAction: () => initialState },
    extraReducers: responseAsyncThunk(checkIsStory()),
});
