import { FC } from 'react';
import ReactQuill from 'react-quill';
import { EditorProps } from 'types';
import 'react-quill/dist/quill.snow.css';
import { TextEditorContainer } from './Theme';

export const Editor: FC<EditorProps> = ({
    activeColors,
    value,
    setValue,
    placeholder,
    readOnly = false,
    setOriginValue,
}) => {
    const colors: string[] = [
        '#000',
        '#0FA7E4',
        '#E52833',
        '#1E1E2D',
        '#A2A5B5',
        '#d0d1d2',
        '#D2691E',
        '#6495ED',
        '#0000FF',
        '#DC143C',
        '#8B008B',
        '#FFE4C4',
        '#A52A2A',
        '#FF8C00',
        '#228B22',
        '#FFD700',
    ];

    const onChange = (content: any, delta: any, source: any, editor: any) => {
        const text = editor.getText(content);
        setOriginValue && setOriginValue(text);
        setValue(content);
    };

    return (
        <TextEditorContainer style={{ height: '100%', width: '100%', position: 'relative' }}>
            {/* toolbar of editor */}
            <div style={{ display: 'flex' }} id="toolbar">
                <button className="ql-bold" />
                <button className="ql-link" />
                {activeColors && (
                    <select defaultValue="#000" className="ql-color">
                        {colors.map((item) => (
                            <option value={item} key={item} />
                        ))}
                    </select>
                )}
            </div>
            {/* the editor */}
            <ReactQuill
                onChange={onChange}
                readOnly={readOnly}
                placeholder={placeholder}
                style={{ height: '90%', width: '100%' }}
                value={value}
                theme="snow"
                modules={{
                    toolbar: { container: '#toolbar' },
                    history: {
                        delay: 500,
                        maxStack: 100,
                        userOnly: true,
                    },
                }}
                preserveWhitespace
            />
        </TextEditorContainer>
    );
};
