import { FC } from 'react';
import { ButtonIconProps } from 'types';
import { ButtonTag } from './theme';

export const ButtonIcon: FC<ButtonIconProps> = ({
    icon: Icon,
    onClick,
    bg,
    size = 30,
    disabled = false,
}) => {
    return (
        <ButtonTag
            onClick={onClick}
            className={bg}
            disabled={disabled}
            style={{
                width: `${size}px`,
                height: `${size}px`,
            }}
        >
            <Icon
                style={{
                    width: `${size / 1.7}px`,
                    height: `${size / 1.7}px`,
                }}
            />
        </ButtonTag>
    );
};
