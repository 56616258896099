import { FC, useEffect } from 'react';
import { Search } from 'assets';
import { Input, Loader } from 'components';
import { useLocales, useLogout } from 'hooks';
import { NoSearchResult, TitleUsers, UsersChat } from '../theme';
import { messageAPI } from 'services/apis';
import { SUCCESS_STATUS } from 'constants/auth';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Room } from './room';

export const ListRooms: FC<any> = ({ handleSelectRoom, currentUser, userParams }) => {
    const { trans, Trans } = useLocales();
    const { register, watch } = useForm<any>();
    const dispatch = useDispatch<any>();
    const handleLogut = useLogout();

    const isLoading = useSelector<any>((state: any) => state.getListOfRooms.loading === 'pending');
    const data: any = useSelector<any>((state: any) => state.getListOfRooms.entities || []);
    const list = Object.keys(data)
        .filter((key: any) => data[key].userId !== userParams?.userId)
        .map((key: any) => data[key]);

    const listUsers = userParams?.user?.profileImage ? [userParams, ...list] : list;

    const getListOfUsersRooms = async (search: any) => {
        const urlParams = search ? `search=${search}` : `search=`;
        const { payload } = await dispatch(messageAPI.getListOfRooms()({ urlParams }));
        if (!SUCCESS_STATUS.includes(payload?.status)) {
            const msg = payload?.message?.message?.trim().split(' ').join('.');
            toast.error(`${trans(msg)}`);
            if (msg === 'user.is.not.allowed.to.login.by.the.system.login.policy') {
                handleLogut();
            }
        }
    };

    useEffect(() => {
        const search = watch('search') ? watch('search') : '';
        getListOfUsersRooms(search);
    }, [watch('search')]);

    return (
        <UsersChat className="flex-column flex-lg-row-auto w-100 w-lg-300px w-xl-400px mb-10 mb-lg-0">
            <div className="pin_searchbar">
                <TitleUsers>
                    <Trans i18nKey={'message.users.list'} />
                </TitleUsers>

                {/* search box */}
                <Input
                    register={register('search')}
                    icon={Search}
                    type="search"
                    bold
                    bgGray
                    placeholder={trans('g.search.label')}
                />
            </div>

            <div className="bowl_result">
                <div className="LEFT_SCROLL">
                    {/* list of rooms */}
                    {listUsers.length ? (
                        listUsers?.map((item: any) => (
                            <Room
                                key={item?.id}
                                item={item}
                                handleSelectRoom={handleSelectRoom}
                                currentUser={currentUser}
                            />
                        ))
                    ) : isLoading ? (
                        <Loader />
                    ) : (
                        <NoSearchResult>
                            <span>لم يتم العثور على نتائج</span>
                        </NoSearchResult>
                    )}
                </div>
            </div>
        </UsersChat>
    );
};
