import { Search } from 'assets';
import { Button } from 'components/button';
import { FormAddAdmin } from 'components/formAddAdmin';
import { useLocales } from 'hooks';
import { FC, useState } from 'react';
import { SearchFieldProps } from 'types';
import { BowlInput, BowlSvg, Form, InputTag, Section } from './theme';

export const SearchField: FC<SearchFieldProps> = ({
    counts,
    name,
    onSubmit,
    searchAction,
    isAdmin,
}) => {
    const { trans } = useLocales();
    const [isOpen, setIsOpen] = useState(false);

    return (
        <Form onSubmit={onSubmit}>
            <div>
                <h3>
                    ({counts || 0}) {name}
                </h3>
            </div>
            <Section className={isAdmin ? 'active' : ''}>
                <BowlInput>
                    <BowlSvg>
                        <Search style={{ width: '35px' }} />
                    </BowlSvg>
                    <InputTag
                        placeholder={trans('g.search.label')}
                        type="search"
                        onChange={searchAction}
                        required
                        name="search"
                    />
                </BowlInput>
                {isAdmin && (
                    <Button
                        title={trans('system.btn.add.admin')}
                        style={{ marginRight: '20px', width: '220px' }}
                        onClick={() => setIsOpen(true)}
                    />
                )}

                {isAdmin && isOpen && <FormAddAdmin isOpen={isOpen} setIsOpen={setIsOpen} />}
            </Section>
        </Form>
    );
};
