import React, { FC, useState, useEffect } from 'react';
import { ImagView } from '../theme';
import { SUCCESS_STATUS } from 'constants/auth';

import { toast } from 'react-toastify';
import { postAPI } from 'services/apis';
import { useDispatch } from 'react-redux';
export const ReportPost: FC<any> = ({ contentType, content }) => {
    const dispatch = useDispatch<any>();
    const [postData, setPostData] = useState([]) as any;

    const fetchSinglePost = async (postId: string) => {
        const { payload } = await dispatch(
            postAPI.getSinglePost()({
                urlParams: `postId=${postId}`,
            }),
        );
        if (SUCCESS_STATUS.includes(payload?.status)) {
            setPostData(payload?.data);
        }
        if (!SUCCESS_STATUS.includes(payload?.status)) {
            toast.error(payload?.message?.message);
        }
    };

    useEffect(() => {
        if (contentType === 'POST') {
            fetchSinglePost(content);
        }
    }, []);
    const hasType =
        postData?.storyMedia?.endsWith('.mp4') ||
        postData?.storyMedia?.endsWith('.avi') ||
        postData?.storyMedia?.endsWith('.mov') ||
        postData?.storyMedia?.endsWith('.wmv') ||
        postData?.storyMedia?.endsWith('.flv') ||
        postData?.storyMedia?.endsWith('.mkv') ||
        postData?.storyMedia?.endsWith('.webm') ||
        postData?.storyMedia?.endsWith('.mpeg') ||
        postData?.storyMedia?.endsWith('.mpg');
    return (
        <>
            <p>{postData?.originContent}</p>
            <div className={`cover`}>
                {hasType ? (
                    <ImagView>
                        <video
                            controls
                            src={`${process.env.REACT_APP_URL_STREAMING}/${postData?.storyMedia}`}
                        />
                    </ImagView>
                ) : postData?.storyMedia ? (
                    <ImagView>
                        <img
                            src={`${process.env.REACT_APP_URL_STREAMING}/${postData?.storyMedia}`}
                            alt=""
                        />
                    </ImagView>
                ) : null}
            </div>
        </>
    );
};
