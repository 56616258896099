import { FC, useState } from 'react';
import { Button, Modal } from 'components';
import { useLocales } from 'hooks';
import { router } from 'routers';
import { DocumentSvg } from 'assets';
import { toast } from 'react-toastify';
import { articlesAPI } from 'services/apis';
import { SUCCESS_STATUS } from 'constants/auth';
import { useDispatch, useSelector } from 'react-redux';

export const FooterEditPost: FC<any> = ({
    justView,
    watch,
    onSaveChanges,
    tempStatus,
    isLoadingSave,
    getValues,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isCancelPage, setIsCancelPage] = useState(false);
    const { trans, Trans } = useLocales();
    const dispatch = useDispatch<any>();

    const isLoadingSaveArchived = useSelector<any>(
        (state: any) => state.deactivateArticle?.loading === 'pending',
    );

    const isLoadingSaveReject = useSelector<any>(
        (state: any) => state.rejectArticle?.loading === 'pending',
    );

    const isLoadingALl = isLoadingSave || isLoadingSaveArchived || isLoadingSaveReject;

    const articleTempStatus: any = {
        APPROVED: {
            btnSave: 'article.editpost.approved.save',
            btnReject: 'article.editpost.approved.hidden',
        },
        PENDING: {
            btnSave: 'article.editpost.pending.save',
            btnReject: 'article.editpost.pending.reject',
        },
        REJECTED: {
            title: 'article.editpost.close',
        },
        ARCHVED: {
            title: 'article.editpost.close',
        },
    };

    // for reject the article
    const rejectArticle = async () => {
        const { payload } = await dispatch(
            articlesAPI.rejectArticle()({ postId: getValues('id') }),
        );
        if (SUCCESS_STATUS.includes(payload?.status) && payload?.data?.tempStatus === 'REJECTED') {
            router.navigate('articles', {
                typeAction: 'sendReject',
                publicId: getValues('publicId'),
            });
        } else {
            setIsOpen(false);
            const msg = trans('toast.msg.you.cant.reject.this.post', {
                defaultValue: payload?.message?.message,
            });
            toast.error(msg);
        }
    };

    // for hidden the article
    const deactivateArticle = async () => {
        const { payload } = await dispatch(
            articlesAPI.deactivateArticle()({ postId: getValues('id') }),
        );
        if (SUCCESS_STATUS.includes(payload?.status) && payload?.data?.tempStatus === 'REJECTED') {
            router.navigate('articles', {
                typeAction: 'sendDeactive',
                publicId: getValues('publicId'),
            });
        } else {
            setIsOpen(false);
            const msg = trans('toast.msg.you.cant.deactivate.this.post', {
                defaultValue: payload?.message?.message,
            });
            toast.error(msg);
        }
    };

    const cancelUpdate = () => {
        if (justView) {
            setIsCancelPage(false);
            router.navigate('articles');
        } else {
            setIsCancelPage(true);
        }
    };

    return (
        <div className="bowl_Save_edits">
            <div className="content">
                {!justView && (
                    <>
                        <Button
                            title={trans(articleTempStatus[tempStatus]?.btnSave)}
                            onClick={onSaveChanges}
                            isLoading={!isOpen && isLoadingALl}
                        />
                        <Button
                            title={trans(articleTempStatus[tempStatus]?.btnReject)}
                            classType={tempStatus === 'PENDING' ? 'primary' : 'outlined'}
                            typeBg="red"
                            onClick={() => setIsOpen(true)}
                        />
                    </>
                )}

                <Button
                    classType={justView ? 'primary' : 'secondary'}
                    title={trans('g.cancel')}
                    onClick={cancelUpdate}
                />
            </div>

            {/* when cancel page */}
            <Modal
                isOpen={isCancelPage}
                setIsOpen={setIsCancelPage}
                onClick={() => router.navigate('articles')}
                icon={DocumentSvg}
                note={'g.pop.article.cancel.note'}
                titleSubmit="g.yes"
                titleCancel="g.no"
                typeSvg="none"
            />

            <Modal
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                onClick={tempStatus === 'APPROVED' ? deactivateArticle : rejectArticle}
                icon={DocumentSvg}
                title={tempStatus === 'APPROVED' ? 'g.pop.article.hide' : 'g.pop.article.reject'}
                isLoading={isOpen && isLoadingALl}
                note={
                    tempStatus === 'APPROVED' ? (
                        <>
                            <Trans i18nKey={'g.pop.article.hide.note.start'} />{' '}
                            <strong>{watch('publicId')}</strong> {'؟ '}
                            <p>
                                <Trans i18nKey={'g.pop.article.hide.note.end'} />
                            </p>
                        </>
                    ) : (
                        <>
                            <Trans i18nKey={'g.pop.article.reject.note.start'} />{' '}
                            <strong>{watch('publicId')}</strong> {'؟ '}
                            <p>
                                <Trans i18nKey={'g.pop.article.reject.note.end'} />
                            </p>
                        </>
                    )
                }
                titleSubmit={tempStatus === 'APPROVED' ? 'g.hidden' : 'g.reject'}
                typeSvg="reject"
                typeBtn="red"
            />
        </div>
    );
};
