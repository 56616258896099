import { RemoveIcon, VideoMark } from 'assets';
import { ButtonIcon } from 'components';
import { FC } from 'react';

export const FileCover: FC<any> = ({
    data,
    justView,
    isMainCover,
    handleRemoveFile,
    storyMedia,
}) => {
    const { originalLink, type } = data;
    const isVideo = type?.includes('video');
    const hasType =
        data?.originalLink?.endsWith('.mp4') ||
        data?.originalLink?.endsWith('.avi') ||
        data?.originalLink?.endsWith('.mov') ||
        data?.originalLink?.endsWith('.wmv') ||
        data?.originalLink?.endsWith('.flv') ||
        data?.originalLink?.endsWith('.mkv') ||
        data?.originalLink?.endsWith('.webm') ||
        data?.originalLink?.endsWith('.mpeg') ||
        data?.originalLink?.endsWith('.mpg');
    return (
        <div className={`cover ${isMainCover && 'main_cover'}`}>
            {!storyMedia && (
                <ButtonIcon
                    onClick={() => handleRemoveFile(data)}
                    disabled={justView}
                    icon={RemoveIcon}
                    size={25}
                />
            )}

            {isVideo || hasType ? (
                <>
                    <video
                        src={
                            originalLink
                                ? `${process.env.REACT_APP_URL_STREAMING}/${originalLink}`
                                : URL.createObjectURL(data)
                        }
                    />
                    <div className="center_mark">
                        <VideoMark />
                    </div>
                </>
            ) : (
                <img
                    src={
                        originalLink
                            ? `${process.env.REACT_APP_URL_STREAMING}/${originalLink}`
                            : URL.createObjectURL(data)
                    }
                    alt=""
                />
            )}
        </div>
    );
};
