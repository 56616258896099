import { FC } from 'react';
import { AvatarOrLetterProps } from 'types';

export const AvatarOrLetter: FC<AvatarOrLetterProps> = ({ user }) => {
    return (
        <div className="symbol symbol-45px symbol-circle">
            {user?.profileImage || user?.coverImage ? (
                <img
                    alt="Pic"
                    src={
                        user?.profileImage?.includes('http') || user?.coverImage?.includes('http')
                            ? user?.profileImage || user?.coverImage
                            : `${process.env.REACT_APP_URL_STREAMING}/${
                                  user?.profileImage || user?.coverImage
                              }`
                    }
                />
            ) : (
                <span
                    className="symbol-label bg-light-primary text-danger font-weight-bold text d-flex align-items-center justify-content-center h1
                    "
                >
                    {user.name?.charAt(0).toUpperCase()}
                </span>
            )}
        </div>
    );
};
