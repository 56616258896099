import { createSlice } from '@reduxjs/toolkit';
import { requestAsyncThunk, responseAsyncThunk } from 'services/templates';

const initialState = {
    loading: 'idle',
    entities: {},
    currentRequestId: undefined,
    error: [],
};

export const getSinglePost = () => {
    return requestAsyncThunk({
        storeName: 'getSinglePost',
        _url: `newsletter/dashboard?`,
        method: 'GET',
        exact: 'single_post',
    });
};

export const getSinglePostSlice = createSlice({
    name: 'getSinglePost',
    initialState,
    reducers: {
        resetAction: () => {
            return initialState;
        },
    },
    extraReducers: responseAsyncThunk(getSinglePost()),
});
