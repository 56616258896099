/* eslint-disable react/jsx-key */
export const TableBody = ({ getTableBodyProps, prepareRow, rows }: any) => {
    return (
        <tbody {...getTableBodyProps()}>
            {rows.map((row: any) => {
                prepareRow(row);
                return (
                    <tr {...row.getRowProps()}>
                        {row.cells.map((cell: any) => {
                            return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                        })}
                    </tr>
                );
            })}
        </tbody>
    );
};
