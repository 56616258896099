import { EyeHidden, EyeShow } from 'assets';
import { HandleErrors } from 'components/handleErrors';
import { useId, FC, useState } from 'react';
import { InputProps } from 'types';
import { Container, LabelValue, BoxInput, InputTag, BoxSvg } from './theme';

export const Input: FC<InputProps> = ({
    label,
    register,
    icon: SvgIcon,
    placeholder,
    error,
    name,
    bgIconColor,
    type = 'text',
    bgGray,
    valid,
    style,
    isEye,
    disabled = false,
    bold,
}) => {
    const [showEye, setShowEye] = useState(false);
    const id = useId();
    const handleEyeShow = () => {
        setShowEye(!showEye);
    };

    return (
        <Container className="input_container">
            <label htmlFor={id}>
                {label && <LabelValue>{label}</LabelValue>}

                <BoxInput
                    style={{ backgroundColor: bgGray ? '#f5f8fa' : '#fff', ...style }}
                    className={valid ? 'isValid' : ''}
                >
                    {SvgIcon && (
                        <BoxSvg style={{ backgroundColor: bgIconColor }}>
                            <SvgIcon style={{ width: '35px' }} />
                        </BoxSvg>
                    )}

                    <InputTag
                        id={id}
                        disabled={disabled}
                        type={showEye ? 'text' : type}
                        required
                        name={name}
                        placeholder={placeholder}
                        {...register}
                        style={{ fontWeight: bold ? 'bold' : 'normal' }}
                    />

                    {isEye && (
                        <BoxSvg
                            onClick={handleEyeShow}
                            style={{ backgroundColor: '#0000', cursor: 'pointer' }}
                        >
                            {showEye ? (
                                <EyeHidden className="eye_hidden" />
                            ) : (
                                <EyeShow className="eye_hidden" />
                            )}
                        </BoxSvg>
                    )}
                </BoxInput>
            </label>

            {error && <HandleErrors error={error} />}
        </Container>
    );
};
