import { FC, useId } from 'react';
import { RadioProps } from 'types';
import { RadioInput } from './theme';

export const Radio: FC<RadioProps> = ({ register, label, value }) => {
    const id = useId();

    return (
        <div
            className="form-check form-check-sm form-check-custom form-check-solid"
            style={{ color: '#A2A5B5' }}
        >
            <RadioInput
                className="form-check-input"
                type="radio"
                id={id}
                value={value}
                data-kt-check="true"
                data-kt-check-target=".widget-9-check"
                {...register}
            />
            <label style={{ color: '#A2A5B5' }} htmlFor={id}>
                {label}
            </label>
        </div>
    );
};
