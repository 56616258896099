import { FC } from 'react';
import { HeaderChat } from '../theme';

export const HeadChatCompo: FC<any> = ({ currentUser }) => {
    return (
        <HeaderChat className="card-header" id="kt_chat_messenger_header">
            <div className="card-title">
                <div className="symbol-group symbol-hover"></div>
                <div className="d-flex justify-content-center flex-column me-3">
                    <span>{currentUser?.user?.name}</span>
                    <span className="fs-7 fw-bold text-gray-400 mt-2">
                        {currentUser?.user?.email}
                    </span>
                </div>
            </div>
        </HeaderChat>
    );
};
