import { Editor, HeadTitle, Button, Loader } from 'components';
import { useLocales, useLogout } from 'hooks';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { pinnedPageAPI } from 'services/apis';
import { Container, Edit, Save, Section } from './theme';
import { SUCCESS_STATUS } from 'constants/auth';
import { useDispatch, useSelector } from 'react-redux';

export const AboutEnbago = () => {
    const [valueAbout, setValueAbout] = useState<string>('');
    const { trans, Trans } = useLocales();
    const dispatch = useDispatch<any>();
    const handleLogut = useLogout();

    const isLoadingSave = useSelector<any>(
        (state: any) => state.updateContentPinned?.loading === 'pending',
    );

    const isLoading = useSelector<any>(
        (state: any) => state.getContentPinned.loading === 'pending',
    );

    const data: any = useSelector<any>(
        (state: any) => state.getContentPinned.entities?.result || {},
    );

    const onSave = async () => {
        const { payload } = await dispatch(
            pinnedPageAPI.updateContentPinned()({
                contentId: data?.id,
                content: valueAbout,
            }),
        );
        if (!SUCCESS_STATUS.includes(payload?.status)) {
            const msg = payload?.message?.message?.trim().split(' ').join('.');
            toast.error(`${trans(msg)}`);
            if (msg === 'user.is.not.allowed.to.login.by.the.system.login.policy') {
                handleLogut();
            }
        } else {
            toast.success(`${trans('toast.alert.save.success')}`);
            setValueAbout(payload?.data.result.content || '');
        }
    };

    const getContentPinned = async () => {
        const { payload } = await dispatch(
            pinnedPageAPI.getContentPinned()({
                urlParams: `type=ABOUT`,
            }),
        );
        if (!SUCCESS_STATUS.includes(payload?.status)) {
            toast.error(payload?.message?.message);
        } else {
            setValueAbout(payload?.data.result.content || '');
        }
    };

    useEffect(() => {
        getContentPinned();
    }, []);

    return (
        <Container>
            <HeadTitle title={trans('about.title')} sub={trans('about.title')} />
            {isLoading ? (
                <Loader />
            ) : (
                <>
                    <Section>
                        <h3 className="text-muted">
                            <Trans i18nKey={'about.label'} />
                        </h3>
                        <Edit>
                            <Editor
                                placeholder={trans('about.title')}
                                value={valueAbout}
                                setValue={setValueAbout}
                            />
                        </Edit>
                    </Section>
                    <Save className="save">
                        <Button
                            isLoading={Boolean(isLoadingSave)}
                            onClick={onSave}
                            title={trans('g.changes.save')}
                            style={{ width: '140px', height: '45px' }}
                        />
                    </Save>
                </>
            )}
        </Container>
    );
};
