import { Error404 } from 'assets';
import { Link } from 'react-router5';

export const NotFound = () => {
    return (
        <>
            <h1 className="fw-bolder fs-2hx text-gray-900 mb-4">Oops!</h1>
            <div className="fw-semibold fs-6 text-gray-500 mb-7">هذه الصفحة غير موجودة</div>
            <div className="mb-3">
                <img src={Error404} className="mw-100 mh-300px theme-light-show" alt="" />
            </div>
            <div className="mb-0">
                <Link routeName="/" className="btn btn-sm btn-primary">
                    العودة للرئيسية
                </Link>
            </div>
        </>
    );
};
