import { AvatarOrLetter, HeadTitle, Table } from 'components';
import { useLocales, useLogout } from 'hooks';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useRoute } from 'react-router5';
import { format } from 'date-fns';
import { ModalRemoveBlock } from './ban';
import { usersAPI } from 'services/apis';
import { useDispatch, useSelector } from 'react-redux';
import { SUCCESS_STATUS } from 'constants/auth';

export const BanList = () => {
    const [error, setErroe] = useState<string>();
    const { trans } = useLocales();
    const { route } = useRoute();
    const dispatch = useDispatch<any>();
    const handleLogut = useLogout();
    const { typeAction, name, id } = route.params;

    useEffect(() => {
        if (typeAction === 'blockUser') {
            toast.success(`${trans('toast.banuser.end')} ${name} ${trans('toast.banuser.start')}`);
        }
    }, [id, typeAction]);

    const isLoading = useSelector<any>(
        (state: any) => state.blockedUserList?.loading === 'pending',
    );

    const data = useSelector<any>((state: any) => state.blockedUserList?.entities?.result?.data);

    const pagination = useSelector<any>(
        (state: any) => state.blockedUserList?.entities?.result?.pagination,
    );

    const getBanLists = async ({ sort, pageIndex, pageSize, search }: any) => {
        const page = pageIndex + 1;
        let dataTosend: any = {
            urlParams: `items_per_page=${pageSize}&current_page=${page}`,
            search: search,
        };

        Object.keys(sort).length && (dataTosend['sort'] = sort?.name);

        const { payload } = await dispatch(usersAPI.blockedUserList()(dataTosend));

        if (!SUCCESS_STATUS.includes(payload?.status)) {
            const msg = payload?.message?.message?.trim().split(' ').join('.');
            toast.error(`${trans(msg)}`);
            setErroe(trans(msg));
            if (msg === 'user.is.not.allowed.to.login.by.the.system.login.policy') {
                handleLogut();
            }
        } else {
            setErroe('');
        }
    };

    const columns = [
        {
            Header: trans('g.useranme'),
            accessor: 'name',
            Cell: ({ row }: any) => {
                const { name, email } = row.original;
                return (
                    <div className="avatar_profile ban">
                        <AvatarOrLetter user={row.original} />
                        <div>
                            <span>{name}</span>
                            <span>{email}</span>
                        </div>
                    </div>
                );
            },
        },
        {
            Header: trans('banlist.ban.date'),
            accessor: 'updatedAt',
            Cell: ({ value }: any) => {
                return <div className="date_ban">{format(new Date(value), 'MMM dd, yyyy')}</div>;
            },
            disableSortBy: true,
        },
        {
            Header: trans('banlist.ban'),
            accessor: 'id',
            Cell: ModalRemoveBlock,
            disableSortBy: true,
            class: 'last_box',
        },
    ];

    return (
        <section>
            <HeadTitle title={trans('banlist.ban.list')} sub={trans('banlist.ban.list')} />

            <Table
                error={error}
                columns={columns}
                data={data}
                pagination={pagination}
                isLoading={isLoading}
                getFetch={getBanLists}
                titleCount={trans('users.the.users.count')}
                msgEmpty={trans('msg.empty.data.banlist')}
            />
        </section>
    );
};
