import { FC } from 'react';
import { Spinner } from 'react-bootstrap';

export const Loader: FC = () => {
    return (
        <div className="loader_container">
            <Spinner animation="border" role="status" />
        </div>
    );
};
