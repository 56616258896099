import styled from 'styled-components';

const Container = styled.div`
    label {
        width: 100%;
    }
`;

const LabelValue = styled.div`
    margin-bottom: 10px !important;
    font-weight: bold;
`;

const BoxInput = styled.div`
    position: relative;
    background-color: #fff;
    display: flex;
    align-items: center;
    width: 100%;
    height: 45px;
    border: 1px solid #e1e3e697;
    border-radius: 8px;

    &.isValid {
        border: 1px solid #f00 !important;
    }

    &:hover {
        border: 1px solid #bfc0c2c7;
    }
`;

const InputTag = styled.input`
    width: 100%;
    outline: none;
    background-color: transparent;

    &::placeholder {
        color: #aaadbb;
        height: 45px;
    }
`;

const BoxSvg = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 100%;

    .eye_hidden {
        fill: rgb(163, 163, 163);
        width: 20px;
        height: 20px;
    }
`;

export { Container, LabelValue, BoxInput, InputTag, BoxSvg };
