import { useLocales } from 'hooks';
import { map } from 'lodash';
import { FC } from 'react';
import { FileCover } from './FileCover';

export const FilesDemo: FC<any> = ({
    data,
    clickMore,
    isShowMore,
    justView,
    handleRemoveFile,
}: any) => {
    const { Trans } = useLocales();

    return (
        <>
            <div className="files_reader">
                {map(data, (item) => {
                    return (
                        <FileCover
                            handleRemoveFile={handleRemoveFile}
                            justView={justView}
                            key={item.id ? item.id : item.name}
                            data={item}
                        />
                    );
                })}
            </div>
            {!isShowMore && (
                <div className="see_more">
                    <button onClick={clickMore}>
                        <Trans i18nKey={'g.show.more'} />
                    </button>
                </div>
            )}
        </>
    );
};
