import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

const AreaText = styled.div`
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    border: 1px solid #e1e3e697;
    border-radius: 8px;
    overflow: hidden;
    height: 100px;
    margin-top: 20px;

    &:hover {
        border: 1px solid #bfc0c2c7;
    }
`;

const TextareaTag = styled.textarea`
    resize: none;
    outline: none;
    height: 100%;
    padding: 10px;
    width: 100%;
    border: none;

    &::placeholder {
        color: #aaadbb;
    }
`;

export { AreaText, TextareaTag, Container };
