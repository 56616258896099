import styled from 'styled-components';

const Sections = styled.section`
    display: flex;
    padding: 10px 0;
    font-size: 1.3rem;
    gap: 30px;

    a {
        padding: 10px;
        color: #a2a5b5;
        position: relative;
        outline: none;
        border: none;
        background-color: transparent;
        display: flex;
        justify-content: center;
        text-decoration: none;

        &:hover {
            color: #767781;
        }

        span {
            color: #e52833;
        }

        &.activeTab {
            color: #0fa7e4;
            font-weight: bold;
            &::after {
                content: '';
                position: absolute;
                bottom: 0;
                background-color: #0fa7e4;
                width: 90%;
                height: 2px;
                border-radius: 4px;
            }
        }
    }
`;

export { Sections };
