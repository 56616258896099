import { FC } from 'react';
import { Button } from 'components/button';
import { SvgIcon, Title, Notes, Btns } from './theme';
import { ModalProps } from 'types';
import { Modal as ModalTag } from 'react-bootstrap';
import { useLocales } from 'hooks';

export const Modal: FC<ModalProps> = ({
    setIsOpen,
    isOpen,
    onClick,
    single,
    icon: Icon,
    title,
    note,
    typeSvg,
    typeBtn,
    titleSubmit,
    isLoading,
    titleCancel,
}) => {
    const { trans, Trans } = useLocales();

    return (
        <ModalTag
            onHide={() => setIsOpen('')}
            show={isOpen ? true : false}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <ModalTag.Body className="modal_content">
                <SvgIcon className={`${typeSvg} ${single ? 'single' : ''}`}>
                    <Icon />
                </SvgIcon>
                {title && (
                    <Title>
                        <Trans i18nKey={title} />
                    </Title>
                )}
                <Notes>
                    <Trans i18nKey={note} />
                </Notes>
                <Btns>
                    <Button
                        style={{ width: single ? '90%' : '44%' }}
                        typeBg={typeBtn}
                        onClick={onClick}
                        isLoading={isLoading}
                        title={trans(titleSubmit)}
                    />
                    {!single && (
                        <Button
                            style={{ width: '44%' }}
                            onClick={() => setIsOpen('')}
                            title={trans(titleCancel ? titleCancel : 'g.cancel')}
                            classType="secondary"
                        />
                    )}
                </Btns>
            </ModalTag.Body>
        </ModalTag>
    );
};
