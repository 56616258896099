import { BlockUser, CommentRemove, MessageType2, RemoveIcon } from 'assets';
import { Button, ButtonIcon, Loader, Modal } from 'components';
import { useLocales } from 'hooks';
import { toast } from 'react-toastify';
import { FC, useEffect, useRef, useState } from 'react';
import {
    CommentsContainer,
    CommentLine,
    Avatar,
    Image,
    Details,
    CommentText,
    Options,
    Content,
    BoxLoadMore,
    CommentTag,
    Replaies,
} from './Theme';
import { useDispatch, useSelector } from 'react-redux';
import { articlesAPI, usersAPI } from 'services/apis';
import { SUCCESS_STATUS } from 'constants/auth';
import moment from 'moment';
import { Link } from 'react-router5';

export const Comments: FC<any> = ({ postId }) => {
    const { trans, Trans } = useLocales();

    const [idRemoveComment, setIdToRemoveComment] = useState<any>();
    const dispatch = useDispatch<any>();
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [idUserForBlock, setIdUserForBlock] = useState<any>();
    const refScroll: any = useRef();

    const isLoading = useSelector<any>((state: any) => state.getComments?.loading === 'pending');
    const isLoadingRemoveComment = useSelector<any>(
        (state: any) => state.deleteComment?.loading === 'pending',
    );
    const data: any = useSelector<any>((state: any) => state.getComments?.entities?.result || []);
    const pagination: any = useSelector<any>(
        (state: any) => state.getComments?.entities?.pagination,
    );

    const TotalPages = Math.ceil(pagination?.count / pagination?.items_per_page);

    // get list of users from api.
    const getListUsers = async (currentPage: number) => {
        await dispatch(
            articlesAPI.getComments()({
                urlParams: `comment?postId=${postId}&items_per_page=35&current_page=${currentPage}`,
            }),
        );
    };

    const loadMoreComments = () => {
        let value = currentPage + 1;
        setCurrentPage(value);
        if (value <= TotalPages) {
            getListUsers(value);
        }
    };

    useEffect(() => {
        if (refScroll?.current) {
            refScroll?.current.scrollTo(0, 0);
        }
    }, [data]);

    useEffect(() => {
        getListUsers(currentPage);
    }, []);

    const onRemoveClick = async () => {
        const { id, isReplay } = idRemoveComment;
        const { payload } = isReplay
            ? await dispatch(articlesAPI.deleteReplay()({ commentId: id }))
            : await dispatch(articlesAPI.deleteComment()({ commentId: id, postId }));
        if (SUCCESS_STATUS.includes(payload?.status)) {
            toast.success(`${trans('toast.remove.comments')}`);
            setIdToRemoveComment('');
            getListUsers(1);
        } else {
            toast.error(payload?.message?.message);
            setIdToRemoveComment('');
        }
    };

    const handleBlockUser = async () => {
        const { userId, name } = idUserForBlock;

        const { payload } = await dispatch(usersAPI.putBlockUser()({ userId, isBlocked: true }));
        if (SUCCESS_STATUS.includes(payload?.status)) {
            toast.success(`${trans('toast.banuser.start')} ${name} ${trans('toast.banuser.end')}`);
            setIdUserForBlock('');
        } else {
            toast.error(payload?.message?.message);
            setIdUserForBlock('');
        }
    };

    const CommentBowl = ({ item, isReplay }: any) => {
        const {
            id,
            user: { profileImage, name, email },
            content,
            userId,
            createdAt,
        } = item;
        const encodedName = encodeURIComponent(name);
        return (
            <CommentLine>
                <Avatar>
                    <Image src={`${process.env.REACT_APP_URL_STREAMING}/${profileImage}`} />
                </Avatar>
                <Content>
                    <Details>
                        <h5>{name}</h5>
                        <span>{moment(createdAt).fromNow()}</span>
                        <Options>
                            <Link
                                className="message_link comment"
                                routeName="messages"
                                routeParams={{
                                    id: userId,
                                    profileImage: `${process.env.REACT_APP_URL_STREAMING}/${profileImage}`,
                                    name: encodedName,
                                    email,
                                }}
                            >
                                <MessageType2 />
                            </Link>
                            <ButtonIcon
                                onClick={() => setIdUserForBlock({ userId, name: name })}
                                bg="bg2"
                                icon={BlockUser}
                            />
                            <ButtonIcon
                                onClick={() => setIdToRemoveComment({ id, isReplay })}
                                bg="bg2"
                                icon={RemoveIcon}
                            />
                        </Options>
                    </Details>
                    <CommentText>
                        <span>{content}</span>
                    </CommentText>
                </Content>
            </CommentLine>
        );
    };

    const Line = ({ item }: any) => {
        const { replyComments } = item;

        return (
            <CommentTag>
                <CommentBowl item={item} />
                <Replaies>
                    {replyComments?.map((item: any) => (
                        <CommentBowl isReplay item={item} key={item.id} />
                    ))}
                </Replaies>
            </CommentTag>
        );
    };

    return (
        <div className="bowl pin_article">
            <div className="hide">
                <h3>
                    <Trans i18nKey={'article.editpost.option.comments'} />
                </h3>
                <div>
                    ({data?.count || 0}) <Trans i18nKey={'article.editpost.option.comments.sub'} />
                </div>
            </div>

            <Modal
                isOpen={idRemoveComment}
                setIsOpen={setIdToRemoveComment}
                onClick={onRemoveClick}
                icon={CommentRemove}
                title="g.pop.comment.remove.title"
                note="g.pop.comment.remove.note"
                titleSubmit="g.remove"
                typeBtn="red"
                isLoading={isLoadingRemoveComment}
            />
            <Modal
                isOpen={idUserForBlock}
                setIsOpen={setIdUserForBlock}
                onClick={handleBlockUser}
                icon={BlockUser}
                title="g.pop.ban"
                note="g.pop.ban.note"
                titleSubmit="users.ban"
                typeBtn="red"
            />

            <CommentsContainer ref={refScroll}>
                {isLoading ? (
                    <Loader />
                ) : (
                    data?.comments?.map((item: any) => <Line item={item} key={item.id} />)
                )}
                {currentPage <= TotalPages && (
                    <BoxLoadMore>
                        <Button onClick={loadMoreComments} title={trans('g.show.more')} />
                    </BoxLoadMore>
                )}
            </CommentsContainer>
        </div>
    );
};
