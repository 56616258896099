import { HeadTitle, Table, BlockUserFun, AvatarOrLetter } from 'components';
import { useState } from 'react';
import { useLocales, useLogout } from 'hooks';
import { format } from 'date-fns';
import { Link } from 'react-router5';
import { MessageType2 } from 'assets';
import { usersAPI } from 'services/apis';
import { useDispatch, useSelector } from 'react-redux';
import { SUCCESS_STATUS } from 'constants/auth';
import { toast } from 'react-toastify';

export const UserManagement = () => {
    const [error, setErroe] = useState<string>();
    const dispatch = useDispatch<any>();
    const { trans } = useLocales();
    const handleLogut = useLogout();
    // aa
    const isLoading = useSelector<any>((state: any) => state.getListUsers?.loading === 'pending');
    const data = useSelector<any>(
        (state: any) => state.getListUsers?.entities?.payload?.data || [],
    );

    const pagination = useSelector<any>(
        (state: any) => state.getListUsers?.entities?.payload?.pagination,
    );

    // get list of users from api.
    const getListUsers = async ({ sort, search = '', pageIndex, pageSize }: any) => {
        const page = pageIndex + 1;
        const dataTosend = {} as any;
        sort && (dataTosend['sort'] = sort);
        Object.keys(sort).length && (dataTosend['sort'] = sort);

        const { payload } = await dispatch(
            usersAPI.getListUsers()({
                urlParams: `items_per_page=${pageSize}&current_page=${page}`,
                search: search,
                ...dataTosend,
            }),
        );

        if (!SUCCESS_STATUS.includes(payload?.status)) {
            const msg = payload?.message?.message?.trim().split(' ').join('.').toLowerCase();
            toast.error(`${trans(msg)}`);
            setErroe(trans(msg));
            if (msg === 'user.is.not.allowed.to.login.by.the.system.login.policy') {
                handleLogut();
            }
        } else {
            setErroe('');
        }
    };

    const columns = [
        {
            Header: trans('g.useranme'),
            accessor: 'name',
            class: 'type_register',
            Cell: ({ row }: any) => {
                const { name, email } = row.original;
                return (
                    <div className="avatar_profile">
                        <AvatarOrLetter user={row.original} />
                        <div>
                            <span>{name}</span>
                            <span>{email}</span>
                        </div>
                    </div>
                );
            },
        },
        {
            Header: trans('users.register.type'),
            accessor: 'type',
            class: 'type_register',
        },
        {
            Header: trans('users.join.date'),
            class: 'type_register',
            accessor: 'createdAt',
            Cell: ({ value }: any) => {
                return format(new Date(value), 'MMM dd, yyyy');
            },
        },
        {
            Header: trans('users.message'),
            disableSortBy: true,
            Cell: ({ row }: any) => {
                const { id, profileImage, name, email } = row.original;
                const encodedName = encodeURIComponent(name);
                return (
                    <Link
                        className="message_link"
                        routeName="messages"
                        routeParams={{ id, profileImage, name: encodedName, email }}
                    >
                        <MessageType2 />
                    </Link>
                );
            },
            class: 'last_box_users',
        },
        {
            Header: trans('users.ban'),
            Cell: BlockUserFun,
            disableSortBy: true,
            class: 'last_box_users',
        },
    ];

    return (
        <section>
            <HeadTitle title={trans('users.management')} sub={trans('users.management')} />
            <Table
                error={error}
                isLoading={isLoading}
                columns={columns}
                data={data}
                titleCount={trans('users.the.users.count')}
                pagination={pagination}
                getFetch={getListUsers}
                msgEmpty={trans('msg.empty.data.users')}
            />
        </section>
    );
};
