import { createSlice } from '@reduxjs/toolkit';
import { requestAsyncThunk, responseAsyncThunk } from 'services/templates';
import { UsersStateProps } from 'types';

const initialState = {
    loading: 'idle',
    entities: {},
    currentRequestId: undefined,
    error: [],
} as UsersStateProps;

// get list of admins
export const getListAdmins = () => {
    return requestAsyncThunk({
        storeName: 'getListAdmins',
        _url: `user/admin/list?`,
        method: 'POST',
    });
};

export const getListAdminsSLice = createSlice({
    name: 'getListAdmins',
    initialState,
    reducers: { resetAction: () => initialState },
    extraReducers: responseAsyncThunk(getListAdmins()),
});

// add a new admin
export const addAdmin = () => {
    return requestAsyncThunk({
        storeName: 'addAdmin',
        _url: `user/admin`,
        method: 'POST',
    });
};

export const addAdminSLice = createSlice({
    name: 'addAdmin',
    initialState,
    reducers: { resetAction: () => initialState },
    extraReducers: responseAsyncThunk(addAdmin()),
});

// update  admin
export const updateAdmin = () => {
    return requestAsyncThunk({
        storeName: 'updateAdmin',
        _url: `user/admin`,
        method: 'PUT',
    });
};

export const updateAdminSLice = createSlice({
    name: 'updateAdmin',
    initialState,
    reducers: { resetAction: () => initialState },
    extraReducers: responseAsyncThunk(updateAdmin()),
});

// block admin
export const blockAdmin = () => {
    return requestAsyncThunk({
        storeName: 'blockAdmin',
        _url: `user/admin/block`,
        method: 'PUT',
    });
};

export const blockAdminSLice = createSlice({
    name: 'blockAdmin',
    initialState,
    reducers: { resetAction: () => initialState },
    extraReducers: responseAsyncThunk(blockAdmin()),
});
