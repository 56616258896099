import { FC } from 'react';
import { HandleErrorsProps } from 'types';

export const HandleErrors: FC<HandleErrorsProps> = ({ error }) => {
    return (
        <div className="fv-plugins-message-container mt-3">
            <div className="fv-help-block">
                <span role="alert">{error}</span>
            </div>
        </div>
    );
};
