import { FC } from 'react';
import { useLocales } from 'hooks';
import { Externallink, Facebook, Telegram, Whatsapp, Love, Like, Sad, Smile } from 'assets';
import { PopDetailsProps } from 'types';

export const PopDetails: FC<PopDetailsProps> = ({ isShard, shares, reacts, reaction }) => {
    const { Trans } = useLocales();
    return (
        <div className="pop_details">
            <div className="counts">
                {isShard ? (
                    <>
                        <Trans i18nKey={'g.shares.with'} />
                        <span>
                            {shares?.shared || 0} <Trans i18nKey={'g.shares.without'} />
                        </span>
                    </>
                ) : (
                    <>
                        <Trans i18nKey={'g.reacts.with'} />
                        <span>
                            {reaction} <Trans i18nKey={'g.reacts.without'} />
                        </span>
                    </>
                )}
            </div>
            <div className="line">
                {isShard ? (
                    <>
                        <Whatsapp />
                        <span>
                            {shares?.whatsapp || 0} <Trans i18nKey={'g.shares.one'} />
                        </span>
                    </>
                ) : (
                    <>
                        <Love />
                        <span>{reacts?.love || 0}</span>
                    </>
                )}
            </div>
            <div className="line">
                {isShard ? (
                    <>
                        <Telegram />
                        <span>
                            {shares?.telegram || 0} <Trans i18nKey={'g.shares.one'} />
                        </span>
                    </>
                ) : (
                    <>
                        <Sad />
                        <span>{reacts?.sad || 0}</span>
                    </>
                )}
            </div>

            <div className="line">
                {isShard ? (
                    <>
                        <Facebook />
                        <span>
                            {shares?.facebook || 0} <Trans i18nKey={'g.shares.one'} />
                        </span>
                    </>
                ) : (
                    <>
                        <Smile />
                        <span>{reacts?.happy || 0}</span>
                    </>
                )}
            </div>
            <div className="line">
                {isShard ? (
                    <>
                        <Externallink />
                        <span>
                            {shares?.others || 0} <Trans i18nKey={'g.shares.one'} />
                        </span>
                    </>
                ) : (
                    <>
                        <Like />
                        <span>{reacts?.like || 0}</span>
                    </>
                )}
            </div>
        </div>
    );
};
