import { FC, useState } from 'react';
import { AvatarOrLetter } from 'components';
import moment from 'moment';
import { LineUser } from '../../theme';

export const Room: FC<any> = ({ item, handleSelectRoom, currentUser }: any) => {
    const { id, user, userId, updatedAt, isRead } = item || {};
    const [isReadMsg, setIsReadMsg] = useState(isRead);

    return (
        <LineUser
            key={id}
            onClick={() => {
                setIsReadMsg(true);
                handleSelectRoom(item);
            }}
            className={` ${currentUser?.userId === userId ? 'active' : ''}`}
        >
            <AvatarOrLetter user={user} />
            {/* username and email  */}
            <div className="user_infos">
                <div className="fs-5 fw-bolder text-gray-900 text-hover-primary mb-2">
                    {user?.name}
                </div>
                <div className="fw-bold text-gray-400"> {user?.email}</div>
            </div>
            {/* active and last seen */}
            <div className="last">
                <span className="text-muted fs-7 mb-1">{moment(updatedAt).fromNow()}</span>
                {!isReadMsg && <div className="online"></div>}
            </div>
        </LineUser>
    );
};
