import { useEffect, useState } from 'react';
import socketIOClient from 'socket.io-client';

export const useSocket = ({ url, query, path, setMessages }: any) => {
    const [socket, setSocket] = useState<any>(null);

    useEffect(() => {
        const newSocket = socketIOClient(url, { query, path });
        setSocket(newSocket);

        newSocket.on('history', (history: any) => {
            setMessages((prevMessages: any) => [...prevMessages, ...history]);
        });

        return () => {
            setMessages([]);
            newSocket.disconnect();
        };
    }, [query?.userId]);

    const sendMessage = (event: string, data: any) => {
        if (socket) {
            socket.emit(event, data);
        }
    };

    const onMessage = (event: string, callback: any) => {
        if (socket) {
            socket.on(event, callback);
        }
    };

    return { socket, sendMessage, onMessage };
};
