import styled from 'styled-components';

const Section = styled.div``;

const Container = styled.div`
    width: 95%;
    height: calc(100vh - 100px);
    overflow: auto;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 20px;
    gap: 15px;
    @media only screen and (max-width: 1000px) {
        height: unset;
    }
`;

const MsgToSelectRoom = styled.div`
    height: 100%;
    overflow: hidden;
    width: 100%;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.3rem;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
    @media only screen and (max-width: 1000px) {
        height: 200px;
    }
`;

const UsersChat = styled.div`
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    border-radius: 12px;
    overflow: hidden;
    direction: ltr;
    width: 30%;
    min-width: 300px;
    position: relative;

    @media only screen and (max-width: 1000px) {
        max-height: 500px;
        overflow: auto;
    }

    .pin_searchbar {
        padding: 20px;
        direction: rtl;
        height: 125px;
    }

    .bowl_result {
        height: calc(100% - 125px);
        overflow: auto;
        direction: ltr;
        padding: 10px;
        .LEFT_SCROLL {
            direction: rtl;
        }
    }
`;

const BoxChat = styled.div`
    overflow: hidden;
    width: 100%;
    height: 100%;

    min-width: 0;
    border-radius: 12px;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
    @media only screen and (max-width: 1000px) {
        max-height: 500px;
    }
`;

const HeaderChat = styled.div`
    min-height: 95px !important;
    height: 12% !important;
`;

const TitleUsers = styled.h2`
    color: #a2a5b5;
    line-height: 40px;
`;

const BoxContentChat = styled.div`
    overflow-y: auto;
    height: 66%;
    width: 100%;
    padding: 15px;
    direction: ltr;

    .diriction_rtl {
        direction: rtl;
    }
`;

const TextareaSender = styled.div`
    min-height: 150px !important;
    height: 22%;
`;

const HeadUser = styled.div`
    display: flex;
    min-width: 250px;
    align-items: center;

    img {
        margin: 0 10px;
    }
`;

const HeadEnbago = styled.div`
    min-width: 250px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;

const NoSearchResult = styled.div`
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const LineUser = styled.button`
    display: flex;
    align-items: center;
    border-radius: 6px;
    margin-top: 7px;
    padding: 0 7px;
    width: 100%;
    height: 80px;
    outline: none;
    border: none;
    background-color: #fafcff;

    &:hover {
        background-color: #ebf3ff;
        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
    }

    &.active {
        background-color: #ebf3ff;
    }

    .last {
        margin-right: auto;
        height: 60%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: space-between;

        .online {
            height: 10px;
            width: 10px;
            border-radius: 50%;
            background-color: #e52833;
        }
    }

    .user_infos {
        margin-right: 7px;
        text-align: right;
    }
`;

const NoMessagesContent = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const ImagView = styled.div`
    width: 350px;
    height: 300px;
    img,
    video {
        width: 100%;
        height: 100%;
    }
`;

export {
    Container,
    TitleUsers,
    UsersChat,
    HeadUser,
    BoxChat,
    BoxContentChat,
    NoMessagesContent,
    HeaderChat,
    TextareaSender,
    HeadEnbago,
    LineUser,
    NoSearchResult,
    ImagView,
    MsgToSelectRoom,
    Section,
};
