/* eslint-disable */
import { BlockAction, External2, PictureSVG, RemoveIcon } from 'assets';
import {
    ButtonIcon,
    HeadTitle,
    Input,
    Modal,
    NotificationSound,
    Textarea,
    Toggle,
} from 'components';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useRoute } from 'react-router5';
import { useLocales } from 'hooks';
import { useDispatch, useSelector } from 'react-redux';
import { articlesAPI, mediaAPI } from 'services/apis';
import { SUCCESS_STATUS } from 'constants/auth';
import { Spinner } from 'react-bootstrap';
import { router } from 'routers';
import { uniqBy } from 'lodash';
import {
    ArticleMedia,
    ArticlesDetails,
    BoxOption,
    Comments,
    FileCover,
    FooterEditPost,
    TheArticle,
} from './edit';
import { EditpostFormTypes } from 'types';
import { toast } from 'react-toastify';
import { BoxSound, FileUploadBox, ToggleTitle } from './edit/Theme';
import { is } from 'date-fns/locale';

export const EditPost = () => {
    const [isOpen, setIsOpen] = useState('');
    const { route } = useRoute();
    const { id } = route.params;
    const { trans, Trans } = useLocales();
    const dispatch = useDispatch<any>();
    SUCCESS_STATUS;
    const [isLoading, setIsLoading] = useState(true);
    const [valueArticle, setValueArticle] = useState<any>('');
    const [originValue, setOriginValue] = useState<string>('');
    const [files, setFiles] = useState<any[]>([]);
    const [removeFiles, setRemoveFiles] = useState<string[]>([]);
    const [fileCover, setFileCover] = useState<any>();

    const {
        register,
        handleSubmit,
        reset,
        getValues,
        watch,
        setValue,
        formState: { errors },
    } = useForm<EditpostFormTypes>();
    const isNotification = watch('isNotification');
    const tempStatus = watch('tempStatus');
    // IS COMMENT ALLWOED
    const allowComment = watch('allowComment');
    const isPopUp = watch('isPopUp');
    const entities: any = useSelector<any>((state: any) => state.getArticleById?.entities || {});

    const isLoadingSavePending = useSelector<any>(
        (state: any) => state.updatePost?.loading === 'pending',
    );

    const isLoadingSaveApproved = useSelector<any>(
        (state: any) => state.sendApprovePost?.loading === 'pending',
    );

    const isLoadingSave = isLoadingSaveApproved || isLoadingSavePending;
    // to check if can pin this post
    const checkIfArticleIsFixed = async () => {
        if (!entities?.isFixed && !getValues('isFixed')) {
            const { payload } = await dispatch(articlesAPI.checkIsFixed()(null));
            if (SUCCESS_STATUS.includes(payload?.status) && payload?.data?.message) {
                setValue('isFixed', true);
            } else {
                setIsOpen('fixed');
                setValue('isFixed', false);
            }
        }
    };

    // to check if can add to story
    // const checkIfArticleIsStory = async () => {
    //     if (!entities?.isFixed && !getValues('isStory')) {
    //         const { payload } = await dispatch(articlesAPI.checkIsStory()(null));
    //         if (SUCCESS_STATUS.includes(payload?.status) && payload?.data?.message) {
    //             setValue('isStory', true);
    //         } else {
    //             setIsOpen('story');
    //             setValue('isStory', false);
    //         }
    //     }
    // };

    useEffect(() => {
        if (isNotification) {
            setValue('notification', {
                title: getValues('title')?.substr(0, 65),
                content: originValue?.substr(0, 240),
                image: '',
                ringtone: 'DEFAULT',
                isPopUp: isPopUp ? isPopUp : false,
            });
        }
    }, [isNotification]);
    const getArtclieData = async () => {
        const { payload } = await dispatch(
            articlesAPI.getArticleById()({ urlParams: `postId=${id}` }),
        );

        if (SUCCESS_STATUS.includes(payload?.status)) {
            const { content, originContent, advertising, advertisingPost, media, allowComment } =
                payload?.data as any;

            const advertisingValue = advertising?.link || advertisingPost?.link;
            reset({
                ...payload?.data,
                allowComment: payload?.data?.allowComment ? false : true,
                isAds: advertisingValue ? true : false,
                advertising: { link: advertisingValue },
            });

            setValueArticle(content);
            setOriginValue(originContent);
            setIsLoading(false);
        } else {
            toast.error(payload?.message?.message);
        }
    };

    // for save and approved the article
    const onSaveChanges = handleSubmit(async (dataForm: any) => {
        const {
            isFixed,
            isStory,
            media,
            advertising,
            title,
            notification,
            isAds,
            isNotification,
            allowComment,
        } = dataForm;
        let formData: any = new FormData();

        formData.append('postId', id);
        formData.append('isStory', isStory);
        formData.append('title', title || '');
        formData.append('isFixed', isFixed);
        formData.append('content', valueArticle);
        formData.append('originContent', originValue);
        formData.append('storyMedia', fileCover);
        formData.append('allowComment', !allowComment);

        if (tempStatus === 'APPROVED' || advertising?.link) {
            formData.append('advertising', JSON.stringify(isAds ? advertising : { link: '' }));
        }
        if (isNotification && notification) {
            const { image, content, ringtone, title: tilteNotifi } = notification || {};
            const cover = image ? media[0]?.originalLink || '' : '';
            const lastNotifi: any = {
                content,
                ringtone,
                image: cover,
                isPopUp: isPopUp ? isPopUp : false,
            };

            tilteNotifi && (lastNotifi['title'] = tilteNotifi);
            formData.append('notification', JSON.stringify(lastNotifi));
        }

        // const allFIles = fileCover ? [fileCover, ...files] : files;
        const allFIles = files;

        // images, videos
        if (allFIles.length) {
            for (let [key, value] of Object.entries(allFIles)) {
                const { type }: any = value;
                if (type.startsWith) {
                    if (type.startsWith('image')) {
                        formData.append('images', value);
                    } else if (type.startsWith('video')) {
                        formData.append('videos', value);
                    }
                }
            }
        }

        // remove files
        if (removeFiles.length) {
            removeFiles.forEach(async (mediaId) => {
                const { payload } = await dispatch(mediaAPI.removeMedia()({ mediaId }));
            });
        }

        if (tempStatus === 'APPROVED') {
            const { payload } = await dispatch(articlesAPI.updatePost()(formData));
            if (SUCCESS_STATUS.includes(payload?.status)) {
                router.navigate(
                    'articles',
                    { typeAction: 'sendSavedUpdate', publicId: dataForm?.publicId },
                    { replace: true },
                );
            } else {
                const msg = payload?.message?.message?.trim().split(' ').join('.').toLowerCase();
                toast.error(`${trans(msg)}`);
            }
        } else {
            const { payload } = await dispatch(articlesAPI.sendApprovePost()(formData));
            if (
                SUCCESS_STATUS.includes(payload?.status) &&
                payload?.data?.tempStatus === 'APPROVED'
            ) {
                router.navigate(
                    'articles',
                    { typeAction: 'sendApproved', publicId: dataForm?.publicId },
                    { replace: true },
                );
            } else {
                const msg = payload?.message?.message?.trim().split(' ').join('.').toLowerCase();
                toast.error(`${trans(msg)}`);
            }
        }
    });

    useEffect(() => {
        document.title = trans('article.editpost.title.tab');
        getArtclieData();
    }, []);

    const onChangeFiles = (filesEvent: any) => {
        const arrAll = [...Object.values(filesEvent), ...files];
        setFiles(uniqBy(arrAll, 'name'));
    };

    const onChangeCover = (file: any) => {
        setFileCover(file[0]);
    };

    const handleRemoveFile = (data: any) => {
        if (data.id) {
            const filter = getValues('media').filter((file: any) => file.id !== data.id);
            setValue('media', filter);
            setRemoveFiles((prev) => [...prev, data.id]);
        } else {
            const filter = files.filter((file: any) => file.name !== data?.name);
            setFiles(filter);
        }
    };

    const handleRemoveCover = () => {
        setFileCover('');
        setValue('storyMedia', '');
    };

    const justView = tempStatus === 'REJECTED' || tempStatus === 'ARCHIVED';
    // "status":"APPROVED" || "PENDING" || "REJECTED" || "ARCHIVED"
    return (
        <div className="editpost">
            <Modal
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                onClick={() => setIsOpen('')}
                icon={BlockAction}
                title={isOpen === 'fixed' ? 'g.pop.articlepin' : 'g.pop.article.story'}
                note={isOpen === 'fixed' ? 'g.pop.articlepin.note' : 'g.pop.article.story.note'}
                titleSubmit="g.oky"
                single
            />

            {isLoading ? (
                <div className="loader_container">
                    <Spinner animation="border" role="status" />
                </div>
            ) : (
                <>
                    <div className="content">
                        <HeadTitle
                            title={trans('g.articles')}
                            sub={trans('g.articles')}
                            type={
                                tempStatus === 'REJECTED' || tempStatus === 'ARCHIVED'
                                    ? trans('article.editpost.title.show')
                                    : trans('article.editpost.title')
                            }
                        />
                        <div className="main_content">
                            {/* article content */}
                            <div className="post_content">
                                <ArticlesDetails watch={watch} tempStatus={tempStatus} />

                                <TheArticle
                                    justView={justView}
                                    error={errors.title && trans('g.input.required')}
                                    valid={errors?.title ? true : false}
                                    titleRegister={register('title')}
                                    valueArticle={valueArticle}
                                    setValueArticle={setValueArticle}
                                    setOriginValue={setOriginValue}
                                />

                                <ArticleMedia
                                    justView={justView}
                                    data={[...watch('media'), ...files]}
                                    onChangeFiles={onChangeFiles}
                                    register={register('files')}
                                    handleRemoveFile={handleRemoveFile}
                                />
                            </div>

                            {/* articles options */}
                            <div className="post_option">
                                <div className="bowl pin_article">
                                    <div className="hide">
                                        <h3 className="col-lg-4 text-muted">
                                            <Trans i18nKey={'article.editpost.option.cover'} />
                                        </h3>
                                    </div>

                                    {[...watch('media'), ...files][0] ? (
                                        <FileCover
                                            isMainCover
                                            justView={justView}
                                            data={[...watch('media'), ...files][0]}
                                            storyMedia={[...watch('media'), ...files][0]}
                                        />
                                    ) : (
                                        <FileUploadBox htmlFor="cover-main-uploads">
                                            <PictureSVG />
                                            <span>Upload</span>
                                        </FileUploadBox>
                                    )}
                                    {/* storyMedia */}
                                    <div className="note">
                                        <span>
                                            <Trans i18nKey={'article.editpost.option.cover.note'} />
                                        </span>
                                    </div>
                                </div>
                                <BoxOption
                                    justView={justView}
                                    name="article_comment"
                                    title={trans('comments')}
                                    note={trans('comment.allowed.or.not.toggle')}
                                    register={register('allowComment')}
                                />
                                <BoxOption
                                    justView={justView}
                                    name="article_fixed"
                                    title={trans('article.editpost.option.pin')}
                                    note={trans('article.editpost.option.pin.note')}
                                    register={register('isFixed')}
                                    onClick={checkIfArticleIsFixed}
                                />

                                <BoxOption
                                    justView={justView}
                                    name="article_story"
                                    title={trans('article.editpost.option.story')}
                                    note={trans('article.editpost.option.story.note')}
                                    register={register('isStory')}
                                    // onClick={checkIfArticleIsStory}
                                />

                                <BoxOption
                                    justView={justView}
                                    name="article_notification"
                                    title={trans('article.editpost.option.notification')}
                                    note={trans('article.editpost.option.notification.note')}
                                    register={register('isNotification')}
                                >
                                    {watch('isNotification') && (
                                        <>
                                            <div className="hide">
                                                <ToggleTitle>
                                                    <Trans i18nKey={'show.popup.for.shar.user'} />
                                                </ToggleTitle>
                                                <Toggle
                                                    name="notifications.add.image"
                                                    register={register('isPopUp')}
                                                />
                                            </div>
                                            <div className="hide">
                                                <ToggleTitle>
                                                    <Trans
                                                        i18nKey={'notifications.add.image.article'}
                                                    />
                                                </ToggleTitle>
                                                <Toggle
                                                    name="notifications.add.image"
                                                    register={register('notification.image')}
                                                />
                                            </div>
                                            <Input
                                                register={register('notification.title', {
                                                    max: 65,
                                                })}
                                                placeholder={trans('article.article.title')}
                                                valid={errors?.notification ? true : false}
                                            />
                                            <Textarea
                                                placeholder={trans(
                                                    'article.editpost.option.notification.description',
                                                )}
                                                register={register('notification.content', {
                                                    required: true,
                                                    max: 240,
                                                })}
                                            />
                                            <BoxSound>
                                                <NotificationSound
                                                    register={register('notification.ringtone')}
                                                />
                                            </BoxSound>
                                        </>
                                    )}
                                </BoxOption>
                                <BoxOption
                                    justView={justView}
                                    name="article_ads"
                                    register={register('isAds')}
                                    title={trans('article.editpost.option.ads')}
                                    note={trans('article.editpost.option.ads.note')}
                                >
                                    {watch('isAds') && (
                                        <Input
                                            placeholder={trans('g.link.add')}
                                            type="url"
                                            icon={External2}
                                            bgIconColor="#F3F6F9"
                                            register={register('advertising.link', {
                                                required: true,
                                            })}
                                            error={errors.advertising && trans('g.input.required')}
                                            valid={errors?.advertising ? true : false}
                                        />
                                    )}
                                </BoxOption>

                                {tempStatus !== 'PENDING' && <Comments postId={id} />}
                            </div>
                        </div>
                    </div>
                    <FooterEditPost
                        isLoadingSave={isLoadingSave}
                        justView={justView}
                        watch={watch}
                        tempStatus={tempStatus}
                        onSaveChanges={onSaveChanges}
                        getValues={getValues}
                    />
                </>
            )}
        </div>
    );
};
