import styled from 'styled-components';

const CommentsContainer = styled.div`
    max-height: 400px;
    overflow: auto;
`;

const CommentTag = styled.div``;

const Replaies = styled.div`
    padding-right: 30px;
`;

const CommentLine = styled.div`
    display: flex;
    align-items: flex-start;
    border-bottom: 1px solid #eee;
    margin-top: 10px;
    border-radius: 5px;
    justify-content: space-between;
    padding: 5px 10px;
    &:hover {
        background-color: #eef1eeb3;
    }
`;

const Avatar = styled.div`
    width: 50px;
    height: 50px;
`;

const Image = styled.img`
    width: 40px;
    height: 40px;
    border-radius: 50%;
`;

const Content = styled.div`
    width: calc(100% - 50px);
`;

const Details = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const BoxLoadMore = styled.div`
    display: flex;
    justify-content: center;
    margin: 10px 0;
`;

const CommentText = styled.div`
    margin: 8px 0;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 5;
`;

const Options = styled.div`
    display: flex;
    gap: 5px;
`;

const FileUploadBox = styled.label`
    background-color: #e1f0ff;
    display: flex;
    margin-top: 15px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 180px;
    border-radius: 15px;
    cursor: pointer;
    position: relative;

    button {
        position: absolute;
        top: 12px;
        right: 12px;
        background-color: rgba(255, 255, 255, 0.692);
        backdrop-filter: blur(-5px);
        z-index: 10;
        &:hover {
            background-color: rgba(255, 255, 255, 0.589);
        }
    }

    img {
        width: 100%;
        height: 100%;
        box-shadow: 0 1px 6px rgba(0, 0, 0, 0.1);
    }

    span {
        color: #0fa7e4;
        font-weight: bold;
    }

    input {
        display: none;
    }

    &:hover {
        box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
    }
`;

const BoxSound = styled.div`
    margin-top: 20px;
`;

const ToggleTitle = styled.h3`
    width: 232px;
    height: 19px;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    letter-spacing: 0.03em;
    color: #a2a5b5;
    display: flex;
    white-space: nowrap;
`;

export {
    CommentsContainer,
    BoxLoadMore,
    Content,
    CommentLine,
    Avatar,
    Image,
    Details,
    CommentText,
    Options,
    FileUploadBox,
    CommentTag,
    Replaies,
    BoxSound,
    ToggleTitle,
};
