import { useLocales } from 'hooks';
import { format } from 'date-fns';
import { FC } from 'react';

export const ArticlesDetails: FC<any> = ({ watch, tempStatus }) => {
    const { trans } = useLocales();

    const RowDetailArt = ({ label, value, color }: any) => {
        return (
            <div className="row mb-7">
                <span className="col-lg-4 fw-bold text-muted">{label}</span>
                <div className="col-lg-8">
                    <span className={`fw-bolder fs-6`} style={{ color: color ? color : '#000' }}>
                        {value}
                    </span>
                </div>
            </div>
        );
    };

    const articleTempStatus: any = {
        APPROVED: {
            title: 'article.published.articles.one',
            color: '#50CD89',
            titleDate: trans('g.publish.date'),
        },
        PENDING: {
            title: 'article.pinned.articles.one',
            color: '#F1BC00',
            titleDate: trans('g.publish.date.pending'),
        },
        REJECTED: {
            title: 'article.rejected.articles.one',
            color: '#E52833',
            titleDate: trans('g.publish.date.rejected'),
        },
        ARCHIVED: {
            title: 'article.archived.articles.one',
            color: '#E52833',
            titleDate: trans('g.publish.date'),
        },
    };

    return (
        <div className="bowl post_details">
            <RowDetailArt label={trans('article.article.number')} value={watch('publicId')} />
            <RowDetailArt
                label={articleTempStatus[tempStatus].titleDate}
                value={format(new Date(watch('createdAt')), 'MMM dd, yyyy')}
            />
            <RowDetailArt label={trans('g.useranme')} value={watch('publisher.name')} />

            {tempStatus !== 'PENDING' && (
                <RowDetailArt label={trans('article.reacts.count')} value="17" />
            )}
            {tempStatus !== 'PENDING' && (
                <RowDetailArt label={trans('article.articles.count')} value="2" />
            )}

            {tempStatus !== 'ARCHIVED' && (
                <RowDetailArt
                    label={trans('g.status')}
                    value={trans(articleTempStatus[tempStatus].title)}
                    color={articleTempStatus[tempStatus].color}
                />
            )}
        </div>
    );
};
