import { FC } from 'react';
import { ButtonProps } from 'types';
import { ButtonTag } from './theme';

export const Button: FC<ButtonProps> = ({
    isLoading,
    disabled,
    title,
    classType = 'primary',
    onClick,
    typeBg,
    style,
}) => {
    return (
        <ButtonTag
            type="submit"
            id="kt_sign_in_submit"
            className={`${classType}`}
            disabled={disabled || isLoading}
            onClick={onClick}
            background={typeBg}
            style={style}
        >
            {!isLoading && <span className="indicator-label">{title}</span>}
            {isLoading && (
                <span className="indicator-progress" style={{ display: 'block' }}>
                    جاري التحقق...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
            )}
        </ButtonTag>
    );
};
