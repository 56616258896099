import { memo, ReactNode } from 'react';
import { ToastContainer } from 'react-toastify';
import styled from 'styled-components';
import { Header } from '../components';
import moment from 'moment';
import 'moment/locale/ar';

moment.locale('ar');

interface AuthLayoutProps {
    children: ReactNode;
}

const Main = styled.main`
    @import '/theme/breakpoints.scss';
    @import '/theme/palette.scss';
    display: flex;
    height: 100vh;
    width: 100vw;
`;

const Content = styled('div')`
    z-index: 1;
    width: 100%;
    flex-grow: 1;
    overflow: auto;
`;

export const AuthLayout = memo(({ children }: AuthLayoutProps) => {
    return (
        <Main>
            <Header />
            <Content>
                <ToastContainer />
                {children}
            </Content>
        </Main>
    );
});
