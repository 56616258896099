import { FC } from 'react';
import { Arrow } from 'assets';
import ReactPaginate from 'react-paginate';
import { PaginationProps } from 'types';

export const Pagination: FC<PaginationProps> = ({
    pageCount,
    handlePageClick,
    initialPage,
    onChangePageSize,
    pagination,
}) => {
    return (
        <div className="bowl_pagination">
            <select
                className="form-select form-select-sm form-select-solid w-50px w-xxl-65px"
                value={pagination?.items_per_page || 10}
                onChange={onChangePageSize}
            >
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={30}>30</option>
                <option value={40}>40</option>
                <option value={50}>50</option>
            </select>
            <ReactPaginate
                breakLabel="..."
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                forcePage={initialPage}
                pageCount={pageCount}
                nextLabel={<Arrow style={{ transform: 'rotate(90deg)' }} />}
                previousLabel={<Arrow style={{ transform: 'rotate(-90deg)' }} />}
            />
        </div>
    );
};
