import styled from 'styled-components';

const Box = styled.form`
    width: 100%;
    height: 320px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
`;

const FieldAdmin = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    color: #464e5f;

    .star {
        color: #e52833;
    }
`;

const Label = styled.div``;

const H2 = styled.h2`
    color: #181c32;
`;

const Btns = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-around;
    gap: 20px;
`;

export { H2, FieldAdmin, Box, Label, Btns };
