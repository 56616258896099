/* eslint-disable react/jsx-key */
import { Arrow } from 'assets';

export const TableHeader = ({ headerGroups }: any) => {
    return (
        <thead>
            {headerGroups.map((headerGroup: any) => {
                return (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column: any) => {
                            return (
                                <th
                                    {...column.getHeaderProps({
                                        ...column.getSortByToggleProps(),
                                        className: column.class,
                                    })}
                                >
                                    {column.isSorted ? (
                                        <div
                                            className={`bowl ${
                                                column.isSorted
                                                    ? column.isSortedDesc
                                                        ? 'desc'
                                                        : 'asc'
                                                    : ''
                                            }`}
                                        >
                                            {column.render('Header')}
                                            {column.canSort && (
                                                <div className="arrows">
                                                    <Arrow />
                                                    <Arrow />
                                                </div>
                                            )}
                                        </div>
                                    ) : (
                                        column.render('Header')
                                    )}
                                </th>
                            );
                        })}
                    </tr>
                );
            })}
        </thead>
    );
};
