import styled from 'styled-components';

const TypeSound = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #a2a5b5;
    h3 {
        color: #a2a5b5;
    }
`;

const Option = styled.div`
    margin-top: 10px;
    color: #1a38e2;
`;

export { TypeSound, Option };
