import { HeadTitle, Table } from 'components';
import { useState } from 'react';
import { useLocales } from 'hooks';
import { format } from 'date-fns';
import { ModalBlockAdmin } from './modalBlockAdmin';
import { useDispatch, useSelector } from 'react-redux';
import { adminsAPI } from 'services/apis';
import { SUCCESS_STATUS } from 'constants/auth';
import { ModalEditAdmin } from './modalEditAdmin';

export const SystemAdministration = () => {
    const [error, setErroe] = useState<string>();
    const dispatch = useDispatch<any>();
    const { trans } = useLocales();

    const isLoading = useSelector<any>((state: any) => state.getListAdmins.loading === 'pending');
    const data = useSelector<any>(
        (state: any) => state.getListAdmins.entities?.payload?.data || [],
    );

    const pagination = useSelector<any>(
        (state: any) => state.getListAdmins.entities?.payload?.pagination,
    );

    const reloadData = useSelector<any>((state: any) => [
        state.addAdmin?.entities?.id,
        state.updateAdmin?.entities,
        state.blockAdmin?.entities?.result?.updatedAt,
    ]);

    const getSystemAdmins = async ({ sort, search, pageIndex, pageSize }: any) => {
        const page = pageIndex + 1;

        let dataTosend: any = {
            urlParams: `items_per_page=${pageSize}&current_page=${page}`,
        };

        Object.keys(sort).length && (dataTosend['sort'] = sort);
        search && (dataTosend['search'] = search);
        const { payload } = await dispatch(adminsAPI.getListAdmins()(dataTosend));

        if (!SUCCESS_STATUS.includes(payload?.status)) {
            setErroe(payload?.message?.message);
        } else {
            setErroe('');
        }
    };

    const columns = [
        {
            Header: trans('system.table.username'),
            accessor: 'name',
            Cell: ({ row }: any) => {
                const { name, email } = row.original;
                return (
                    <div className="avatar_profile">
                        <div>
                            <span className="admin_name">{name}</span>
                            <span>{email}</span>
                        </div>
                    </div>
                );
            },
        },
        {
            Header: trans('system.table.date.added'),
            accessor: 'createdAt',
            Cell: ({ row }: any) => {
                const { createdAt } = row.original;
                return format(new Date(createdAt), 'MMM dd, yyyy');
            },
            class: 'center_Date',
        },
        {
            Header: trans('g.edit'),
            disableSortBy: true,
            class: 'last_box',
            Cell: ModalEditAdmin,
        },
        {
            Header: trans('users.ban'),
            Cell: ModalBlockAdmin,
            class: 'last_box',
            disableSortBy: true,
        },
    ];

    return (
        <section>
            <HeadTitle title={trans('g.system')} sub={trans('g.system')} />

            <Table
                titleCount={trans('system.the.admins.count')}
                isAdmin
                error={error}
                columns={columns}
                data={data}
                pagination={pagination}
                isLoading={isLoading}
                getFetch={getSystemAdmins}
                msgEmpty={trans('msg.empty.data.admin')}
                reloadData={reloadData}
            />
        </section>
    );
};
