import { memo, ReactNode } from 'react';
import styled from 'styled-components';

interface AuthLayoutProps {
    children: ReactNode;
}
const Content = styled('section')`
    z-index: 1;
    width: 100%;
    flex-grow: 1;
    overflow: auto;
`;

export const LoginLayout = memo(({ children }: AuthLayoutProps) => {
    return <Content>{children}</Content>;
});
