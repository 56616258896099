import { Input, NotificationSound, Toggle, Textarea, HeadTitle, Button } from 'components';
import { useLocales, useLogout } from 'hooks';

import {
    BoxSender,
    Container,
    CoverNofi,
    Details,
    Footer,
    InputBox,
    IsAds,
    Page,
    Scroll,
    SoundType,
    TitleAds,
    Wrapper,
    PositionedDiv,
    StoryImage,
} from './theme';
import { External2, PictureSVG, RemoveIcon } from 'assets';
import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SUCCESS_STATUS } from 'constants/auth';
import { notificationAPI } from 'services/apis';
import { toast } from 'react-toastify';
import { NotificationManFormTypes } from 'types';
import { FileUploadBox } from 'pages/editPost/edit/Theme';
import { ButtonIcon } from 'components';

export const NotificationManagement = () => {
    const [coverReader, setCoverReader] = useState('');
    const [file, setFile] = useState('');

    // const { article } = route.params;
    const { trans, Trans } = useLocales();
    const dispatch = useDispatch<any>();
    const handleLogut = useLogout();
    const article = useSelector<any>(
        (state: any) => state.sendNotification?.entities?.article?.payload,
    ) as any;

    // const { article } = route.params;

    const isLoadingSave = useSelector<any>(
        (state: any) => state.sendNotification?.loading === 'pending',
    );

    const {
        register,
        handleSubmit,
        watch,
        reset,
        formState: { errors },
    } = useForm<NotificationManFormTypes>({
        defaultValues: {
            title: '',
            content: '',
            image: '',
            ringtone: 'DEFAULT',
        },
    });

    useEffect(() => {
        if (article) {
            const { id, title, content } = article;
            reset({
                title: title?.substr(0, 65),
                content: content?.substr(0, 240),
                postId: id,
            });
        }
    }, [article]);
    const onChangeFiles = (e: any) => {
        const file = e.target.files[0];
        if (!file) return;
        let reader = new FileReader() as any;
        reader.readAsDataURL(file);
        reader.onload = () => {
            setCoverReader(reader.result);
            setFile(file);
        };
    };

    const onSendNotification = handleSubmit(
        async ({ title, content, ringtone, advertising }: any) => {
            const formData = new FormData();
            formData.append('title', title);
            formData.append('content', content);
            formData.append('image', file);
            formData.append('ringtone', ringtone);
            article?.id ? formData.append('postId', article?.id) : undefined;
            if (watch('isAds') && advertising?.link) {
                formData.append('advertising', JSON.stringify(advertising));
            }

            const { payload } = await dispatch(notificationAPI.sendNotification()(formData));
            if (!SUCCESS_STATUS.includes(payload?.status)) {
                const msg = payload?.message?.message?.trim().split(' ').join('.');
                toast.error(`${trans(msg)}`);
                if (msg === 'user.is.not.allowed.to.login.by.the.system.login.policy') {
                    handleLogut();
                }
            } else {
                toast.success(`${trans('toast.send.notification.success')}`);
            }
        },
    );

    const handleRemoveCover = (e: any) => {
        e.preventDefault();
        setCoverReader('');
        setFile('');
    };

    useEffect(() => {
        return () => {
            dispatch(notificationAPI.sendNotificationSLice.actions.resetAction());
        };
    }, []);

    return (
        <Page>
            <Scroll>
                <HeadTitle title={trans('g.notification')} sub={trans('g.notification')} />

                <Container>
                    <BoxSender>
                        <h3 className="col-lg-4 text-muted ">
                            <Trans i18nKey={'notifications.title.input'} />
                        </h3>
                        <InputBox>
                            <Input
                                placeholder={trans('notifications.placeholder.input')}
                                type="text"
                                bgIconColor="#F3F6F9"
                                register={register('title', { required: false, max: 65 })}
                            />
                        </InputBox>
                        <div className="note">
                            <span className="text-muted fs-7">
                                <Trans i18nKey={'notifications.note.max.title'} />
                            </span>
                        </div>
                        <Textarea
                            style={{ height: '120px' }}
                            placeholder={trans('notifications.placeholder.textarea')}
                            error={errors.content && trans('g.input.required')}
                            register={register('content', { required: true, max: 240 })}
                        />
                        <div className="note">
                            <span className="text-muted fs-7">
                                <Trans i18nKey={'notifications.note.max.textarea'} />
                            </span>
                        </div>
                    </BoxSender>
                    <Details>
                        <CoverNofi>
                            <h3 className="col-lg-4 text-muted">
                                <Trans i18nKey={'notifications.cover.label'} />
                            </h3>
                            <label htmlFor="cover-notification-uploads">
                                {coverReader ? (
                                    <Wrapper>
                                        <PositionedDiv>
                                            <ButtonIcon
                                                onClick={(e: any) => handleRemoveCover(e)}
                                                icon={RemoveIcon}
                                                size={25}
                                            />
                                        </PositionedDiv>

                                        <StoryImage
                                            src={coverReader}
                                            alt="story nitification  cover "
                                            style={{ width: 270, height: 175, objectFit: 'cover' }}
                                        />
                                    </Wrapper>
                                ) : (
                                    <FileUploadBox>
                                        <PictureSVG />
                                        <span>Upload</span>
                                        <input
                                            onChange={onChangeFiles}
                                            id="cover-notification-uploads"
                                            type="file"
                                            accept="image/*"
                                        />
                                    </FileUploadBox>
                                )}
                            </label>
                        </CoverNofi>

                        <IsAds>
                            <TitleAds>
                                <h3 className="text-muted">
                                    <Trans i18nKey={'article.editpost.option.ads'} />
                                </h3>
                                <Toggle name="isAds" register={register('isAds')} />
                            </TitleAds>

                            <div className="note">
                                <Trans i18nKey={'article.editpost.option.ads.note'} />
                            </div>
                            {watch('isAds') ? (
                                <Input
                                    placeholder={trans('g.link.add')}
                                    type="url"
                                    icon={External2}
                                    error={errors?.advertising && trans('g.input.required')}
                                    bgIconColor="#F3F6F9"
                                    register={register('advertising.link')}
                                />
                            ) : null}
                        </IsAds>
                        <SoundType>
                            <NotificationSound register={register('ringtone')} />
                        </SoundType>
                    </Details>
                </Container>
            </Scroll>

            <Footer>
                <Button
                    isLoading={isLoadingSave ? true : false}
                    onClick={onSendNotification}
                    title={trans('notifications.submit.tosend')}
                />
            </Footer>
        </Page>
    );
};
