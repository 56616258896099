import * as pages from 'pages';
import { AuthLayout, LoginLayout } from 'layout';
import { isEmpty, get, includes, find } from 'lodash';

const pageRoutes = {
    home: {
        Page: pages.UserManagement,
        Layout: AuthLayout,
    },

    '404': {
        Page: pages.NotFound,
        Layout: AuthLayout,
    },

    loading: {
        Page: pages.Loading,
        Layout: AuthLayout,
    },

    editpost: {
        Page: pages.EditPost,
        Layout: AuthLayout,
    },

    login: {
        Page: pages.Login,
        Layout: LoginLayout,
    },

    articles: {
        Page: pages.ArticleManagement,
        Layout: AuthLayout,
    },

    articlesTab: {
        Page: pages.ArticleManagement,
        Layout: AuthLayout,
    },

    messages: {
        Page: pages.MessageManagement,
        Layout: AuthLayout,
    },

    usermessages: {
        Page: pages.MessageManagement,
        Layout: AuthLayout,
    },

    customnotification: {
        Page: pages.NotificationManagement,
        Layout: AuthLayout,
    },

    notification: {
        Page: pages.NotificationManagement,
        Layout: AuthLayout,
    },
    system: {
        Page: pages.SystemAdministration,
        Layout: AuthLayout,
    },
    banlist: {
        Page: pages.BanList,
        Layout: AuthLayout,
    },

    about: {
        Page: pages.AboutEnbago,
        Layout: AuthLayout,
    },

    terms: {
        Page: pages.TermsAndPolicy,
        Layout: AuthLayout,
    },
};

const pageStructure = (route: any): any => {
    if (isEmpty(route)) {
        return 'loading...';
    }

    const routePaths = Object.keys(get(route, 'meta.params', {}));
    const pageRoute =
        find(routePaths.reverse(), (route: string) => includes(Object.keys(pageRoutes), route)) ||
        '404';

    return pageRoutes[pageRoute as keyof typeof pageRoutes];
};

export default pageStructure;
