import styled from 'styled-components';

const Page = styled.div`
    position: relative;
    height: 100vh;
`;

const Scroll = styled.div`
    overflow: scroll;
    height: calc(100vh - 80px);
`;

const Footer = styled.div`
    background-color: #fff;
    position: absolute;
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 30px;
    left: 0;
    right: 0;
    bottom: 0;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
    z-index: 10;
`;

const Container = styled.div`
    display: flex;
    justify-content: space-around;
    padding: 30px;

    @media only screen and (max-width: 900px) {
        flex-direction: column;
        gap: 30px;
        align-items: center;
    }

    @media only screen and (max-width: 600px) {
        padding: 15px 5px;
    }
`;

const InputBox = styled.div`
    width: 300px;
`;

const BoxSender = styled.div`
    width: 740px;
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0 1px 7px rgba(0, 0, 0, 0.1);
    padding: 10px;
    height: max-content;
    .note {
        margin-top: 7px;
    }

    @media only screen and (max-width: 900px) {
        width: 90%;
    }
`;

const Details = styled.div`
    width: 360px;
    min-width: 360px;
    @media only screen and (max-width: 900px) {
        width: 90%;
    }
`;

const CoverNofi = styled.div`
    background-color: #fff;
    border-radius: 12px;
    padding: 20px;
    box-shadow: 0 1px 7px rgba(0, 0, 0, 0.1);
    height: 260px;
    display: flex;
    flex-direction: column;
    align-items: center;

    h3 {
        width: 100%;
    }

    label {
        background-color: #e1f0ff;
        display: flex;
        margin-top: 15px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 15px;
        overflow: hidden;
        cursor: pointer;
        height: 180px;
        width: 260px;

        img {
            width: 100%;
            height: 100%;
            box-shadow: 0 1px 6px rgba(0, 0, 0, 0.1);
        }

        span {
            color: #0fa7e4;
            font-weight: bold;
        }

        input {
            display: none;
        }

        &:hover {
            box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
        }
    }
`;

const IsAds = styled.div`
    background-color: #fff;
    border-radius: 16px;
    box-shadow: 0 1px 7px rgba(0, 0, 0, 0.1);
    margin-top: 15px;
    padding: 20px;

    .note {
        color: #aaadbb;
        margin: 10px 0;
        font-size: 0.9rem;
    }
`;

const TitleAds = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const SoundType = styled.div`
    background-color: #fff;
    border-radius: 16px;
    box-shadow: 0 1px 7px rgba(0, 0, 0, 0.1);
    margin-top: 15px;
    padding: 20px;
`;
const Wrapper = styled.div`
    position: relative;
`;

const PositionedDiv = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    margin: 10px;
    z-index: 1;
    background: #e1f0ff;
    border-radius: 5px;
`;

const StoryImage = styled.img`
    width: 270px;
    height: 175px;
    object-fit: cover;
`;
export {
    TitleAds,
    SoundType,
    Page,
    Footer,
    Details,
    Scroll,
    CoverNofi,
    IsAds,
    BoxSender,
    Container,
    InputBox,
    Wrapper,
    PositionedDiv,
    StoryImage,
};
