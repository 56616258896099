import { useLocales } from 'hooks';
import { Sections } from '../../../pages/articleManagement/theme';
import { Link } from 'react-router5';

export const ArticlesTabs = ({ currentTab, setCurrentTab, pendingCount, gotoPage }: any) => {
    const { Trans } = useLocales();

    const handelTitleToggle = (tab: string) => {
        return {
            className: currentTab === tab ? 'activeTab' : '',
            routeName: 'articles',
            routeParams: { tab },
            onClick: () => {
                gotoPage(0);
                setCurrentTab(tab);
            },
        };
    };

    return (
        <Sections className="articles_toggle">
            <Link {...handelTitleToggle('APPROVED')}>
                <Trans i18nKey={'article.published.articles'} />
            </Link>
            <Link {...handelTitleToggle('PENDING')}>
                <Trans i18nKey={'article.pinned.articles'} />
                <span>&nbsp; ({pendingCount})</span>
            </Link>

            <Link {...handelTitleToggle('REJECTED')}>
                <Trans i18nKey={'article.rejected.articles'} />
            </Link>

            <Link {...handelTitleToggle('ARCHIVED')}>
                <Trans i18nKey={'article.archived.articles'} />
            </Link>
        </Sections>
    );
};
