import { BgQuotes, EnbagoLogo } from 'assets';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Input } from 'components';
import { useLocales } from 'hooks';
import { LoginFormTypes } from 'types';
import { authAPI } from 'services/apis';
import { useDispatch, useSelector } from 'react-redux';
import { SUCCESS_STATUS } from 'constants/auth';
import { router } from 'routers';

export const Login = () => {
    const dispatch = useDispatch<any>();
    const authLoading = useSelector<any>((state) => state.auth.loading === 'pending');

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<LoginFormTypes>();

    const { trans, Trans } = useLocales();

    const [error, setErroe] = useState<boolean>(false);

    const login = handleSubmit(async (data: any) => {
        const { payload } = await dispatch(authAPI.signIn()({ ...data }));
        if (SUCCESS_STATUS.includes(payload?.status)) {
            router.navigate('articles', {}, { replace: true });
            setErroe(false);
        } else {
            setErroe(true);
        }
    });

    return (
        <section className="login">
            <div className="logos">
                <BgQuotes />
                <img src={EnbagoLogo} alt="" />
                <BgQuotes />
            </div>
            <div className="form_container">
                <form className="form">
                    <div className="mb-11">
                        <h1 className="text-dark fw-bolder mb-3">
                            <Trans i18nKey={'login.title.login'} />
                        </h1>
                    </div>

                    <Input
                        label={trans('login.email.label')}
                        register={register('email', { required: true })}
                        name="email"
                        type="email"
                        placeholder={trans('login.email.placeholder')}
                        bgGray
                        valid={errors.email || error ? true : false}
                        error={errors.email && trans('g.input.required')}
                    />

                    <Input
                        error={
                            errors.password
                                ? trans('g.input.required')
                                : error
                                ? trans('errors.login.valid')
                                : ''
                        }
                        label={trans('login.password.label')}
                        register={register('password', { required: true })}
                        name="password"
                        type="password"
                        placeholder={trans('login.password.placeholder')}
                        bgGray
                        valid={errors.password || error ? true : false}
                        isEye
                    />

                    <Button
                        title={trans('login.btn.logged')}
                        isLoading={Boolean(authLoading)}
                        disabled={Boolean(authLoading)}
                        style={{ width: '100%' }}
                        onClick={login}
                    />

                    <div className="mt-4">
                        <span style={{ color: '#181C32' }}>
                            All rights reserved. Nadsoft 2022 ©{' '}
                        </span>
                    </div>
                </form>
            </div>
        </section>
    );
};
