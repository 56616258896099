import styled from 'styled-components';

const ButtonTag: any = styled.button`
    border-radius: '12px';
    outline: none;
    border: none;
    color: #fff;
    font-size: 1.1rem;
    background-color: #0fa7e4;
    border-radius: 12px;
    height: 48px;
    width: fit-content;
    font-weight: bold;
    padding: 0 15px;

    &.primary {
        background-color: ${({ background }: any) =>
            background === 'red' || background === 'reject' ? '#E52833' : '#0FA7E4'};

        &:hover {
            background-color: ${({ background }: any) =>
                background === 'red' || background === 'reject' ? '#e52832c8' : '#0fa8e4dd'};
        }
    }

    &.secondary {
        background-color: #f6f8fa;
        color: #7f8297;

        &:hover {
            background-color: #f0f0f0;
        }
    }

    &.outlined {
        background-color: transparent;
        color: #0fa7e4;
        border: 1px solid #0fa7e4;

        &:hover {
            color: #78d5fa;
            border: 1px solid #78d5fa;
        }
    }
`;

export { ButtonTag };
