import { EnbagoLogoShort } from 'assets';
import { FC, useEffect, useRef } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import { BoxContentChat, HeadEnbago, HeadUser, ImagView, NoMessagesContent } from '../theme';
import { AvatarOrLetter } from 'components';
import { useLocales } from 'hooks';
import { ReportPost } from './ReportPost';

export const ContentChats: FC<any> = ({ currentUser, messages }) => {
    const moveToBottom = useRef<any>();
    const { Trans } = useLocales();

    useEffect(() => {
        moveToBottom.current.scrollTo(0, moveToBottom.current.scrollHeight);
    }, [messages]);

    return (
        <BoxContentChat ref={moveToBottom}>
            <div className="diriction_rtl">
                {messages?.length ? (
                    messages.map((item: any, index: any) => {
                        const { content, contentType, type, createdAt } = item;

                        const state = type !== 'REQUEST' ? 'info' : 'primary';
                        const contentClass = `d-flex justify-content-${
                            type === 'REQUEST' ? 'start' : 'end'
                        } mb-10`;

                        const clas1 = clsx(
                            'd-flex flex-column align-items',
                            `align-items-${type === 'REQUEST' ? 'start' : 'end'}`,
                        );

                        const clas2 = clsx(
                            'p-5 rounded',
                            `bg-light-${state}`,
                            'text-dark fw-bold mw-lg-400px',
                            `text-${type !== 'REQUEST' ? 'start' : 'end'}`,
                        );

                        return (
                            <div
                                key={`message${index}`}
                                className={clsx('d-flex', contentClass, 'mb-10')}
                            >
                                <div className={clas1}>
                                    {/* the user */}
                                    <div className="d-flex align-items-center mb-2">
                                        {type === 'REQUEST' ? (
                                            <HeadUser>
                                                <AvatarOrLetter user={currentUser?.user} />
                                                <div className="ms-3">
                                                    <h4>{currentUser?.user?.name}</h4>
                                                </div>
                                                <span className="text-muted fs-7">
                                                    {moment(createdAt).fromNow()}
                                                </span>
                                            </HeadUser>
                                        ) : (
                                            <HeadEnbago>
                                                <span className="text-muted fs-7 ms-3">
                                                    {moment(createdAt).fromNow()}
                                                </span>
                                                <h3 className="ms-3">انباغو</h3>
                                                <div className="symbol  symbol-35px symbol-circle ms-3">
                                                    <EnbagoLogoShort />
                                                </div>
                                            </HeadEnbago>
                                        )}
                                    </div>

                                    {/* the message */}
                                    <div className={clas2}>
                                        {contentType === 'IMAGE' ? (
                                            <ImagView>
                                                <img
                                                    src={`${process.env.REACT_APP_URL_STREAMING}/${content}`}
                                                    alt=""
                                                />
                                            </ImagView>
                                        ) : contentType === 'VIDEO' ? (
                                            <ImagView>
                                                <video
                                                    controls
                                                    src={`${process.env.REACT_APP_URL_STREAMING}/${content}`}
                                                />
                                            </ImagView>
                                        ) : contentType === 'POST' ? (
                                            <>
                                                <ReportPost
                                                    content={content}
                                                    contentType={contentType}
                                                />
                                                <p
                                                    className="text-align-right
                                                    text-muted fs-5
                                                    color-gray-600
                                                    d-flex
                                                    justify-content-end
                                                mt-3
"
                                                >
                                                    {moment(createdAt).format('LT')}
                                                </p>
                                            </>
                                        ) : (
                                            <p>{content}</p>
                                        )}
                                    </div>
                                </div>
                            </div>
                        );
                    })
                ) : (
                    <NoMessagesContent>
                        {<Trans i18nKey={'message.no.content.messages'} />}
                    </NoMessagesContent>
                )}
            </div>
        </BoxContentChat>
    );
};
