import { get } from 'lodash';
import { PUBLIC_ROUTES } from 'constants/auth';
import { persistor, store } from 'app/store';

const authenticationMiddleware = () => (toState: any, fromState: any, done: any) => {
    const authAction = () => {
        const nextState = get(toState, 'name', null);
        const isPublicRoute = PUBLIC_ROUTES.includes(nextState);
        const hasAccessToken = store.getState().auth?.entities?.tokens?.access?.token;

        const isPrivatePage = !isPublicRoute && hasAccessToken;
        const isPublicPage = isPublicRoute && !hasAccessToken;

        if (store.getState().auth?.entities?.user?.role === 'ADMIN' && nextState === 'system') {
            return done({
                redirect: {
                    name: 'home',
                    path: '/',
                    meta: { options: { replace: true }, redirected: true },
                },
            });
        }

        if (isPrivatePage || isPublicPage) {
            done();
            return;
        }

        if (hasAccessToken) {
            if (nextState === 'home') {
                done();
                return;
            }

            done({
                redirect: {
                    name: 'home',
                    path: '/',
                    meta: { options: { replace: true }, redirected: true },
                },
            });
            return;
        }

        if (nextState === 'login') {
            done();
            return;
        }
        done({
            redirect: {
                name: 'login',
                path: '/login',
                meta: { options: { replace: true }, redirected: true },
            },
        });
        return;
    };

    if (persistor.getState().bootstrapped) authAction();
    persistor.subscribe(authAction);
};

export default authenticationMiddleware;
