import { AvatarOrLetter, HeadTitle, Table } from 'components';
import { useLocales, useLogout } from 'hooks';
import { useEffect, useState } from 'react';
import { Link, useRoute } from 'react-router5';
import { toast } from 'react-toastify';
import { ArrowType2, InfoIcon, MessageType2, NotificationsType2 } from 'assets';
import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { articlesAPI } from 'services/apis';
import { SUCCESS_STATUS } from 'constants/auth';
import { ToggleIsPin } from './toggleIsPin';
import { PopDetails } from './popDetails';
import { router } from 'routers';
import { notificationAPI } from 'services/apis';
export const ArticleManagement = () => {
    const [error, setErroe] = useState<string>();
    const { trans } = useLocales();
    const { route } = useRoute();
    const { typeAction, publicId, tab } = route.params;
    const dispatch = useDispatch<any>();
    const handleLogut = useLogout();

    const isTabInside = ['APPROVED', 'PENDING', 'REJECTED', 'ARCHIVED'];

    const [currentTab, setCurrentTab] = useState<string>(
        isTabInside.includes(tab?.toUpperCase()) ? tab.toUpperCase() : 'APPROVED',
    );

    const isLoading = useSelector<any>(
        (state: any) => state.getListArticles?.loading === 'pending',
    );

    const data: any = useSelector<any>(
        (state: any) => state.getListArticles?.entities?.result?.data || [],
    );

    const pendingCount = useSelector<any>(
        (state: any) => state.getListArticles?.entities?.result?.pendingCount || 0,
    );

    const pagination = useSelector<any>(
        (state: any) => state.getListArticles?.entities?.result?.pagination,
    );

    useEffect(() => {
        if (typeAction === 'sendApproved') {
            toast.success(
                `${trans('toast.article.approved.start')} ${publicId} ${trans(
                    'toast.article.approved.end',
                )}`,
            );
        } else if (typeAction === 'sendSavedUpdate') {
            toast.success(
                `${trans('toast.article.updated.start')} ${publicId} ${trans(
                    'toast.article.approved.end',
                )}`,
            );
        } else if (typeAction === 'sendReject') {
            toast.success(
                `${trans('toast.article.reject.start')} ${publicId}  ${trans(
                    'toast.article.reject.end',
                )}`,
            );
        } else if (typeAction === 'sendDeactive') {
            toast.success(
                `${trans('toast.article.deactive.start')} ${publicId}  ${trans(
                    'toast.article.deactive.end',
                )}`,
            );
        }
    }, [publicId, typeAction]);

    // get list of articles from api.
    const getListArtciles = async ({ sort, pageSize, search, pageIndex }: any) => {
        const page = pageIndex + 1;
        const req = { status: currentTab } as any;
        search && (req['search'] = search);

        if (Object.keys(sort).length) {
            const key = Object.keys(sort)[0];
            if (key === 'updatedAt') {
                req['sort'] = {
                    [currentTab === 'APPROVED' ? 'publishedDate' : 'updatedAt']: sort[key],
                };
            } else {
                req['sort'] = sort;
            }
        }

        const { payload } = await dispatch(
            articlesAPI.getListArticles()({
                urlParams: `items_per_page=${pageSize}&current_page=${page}`,
                ...req,
            }),
        );
        console.log('payload: ', payload);

        if (!SUCCESS_STATUS.includes(payload?.status)) {
            const msg = payload?.message?.message?.trim().split(' ').join('.');
            toast.error(`${trans(msg)}`);
            setErroe(trans(msg));
            if (msg === 'user.is.not.allowed.to.login.by.the.system.login.policy') {
                handleLogut();
            }
        } else {
            setErroe('');
        }
    };

    const handelnClickNotification = (article: any) => {
        dispatch(notificationAPI.sendNotificationSLice.actions.setNotificationArticle(article));
        router.navigate('notification');
    };
    // for table articles
    const columns = [
        {
            Header: 'id',
            accessor: 'id',
            Cell: ({ row }: any) => {
                const { publicId } = row.original;
                return publicId;
            },
        },
        {
            Header: trans('g.useranme'),
            accessor: 'userName',
            Cell: ({ row }: any) => {
                const { name, email } = row.original.publisher;

                return (
                    <div className="avatar_profile">
                        <AvatarOrLetter user={row.original.publisher} />
                        <div>
                            <span>{name}</span>
                            <span>{email}</span>
                        </div>
                    </div>
                );
            },
        },
        {
            Header: trans('article.the.article'),
            accessor: 'content',
            disableSortBy: true,
            Cell: ({ row }: any) => {
                const { content } = row.original;
                return (
                    <div
                        className="article_content_dots"
                        dangerouslySetInnerHTML={{ __html: content }}
                    />
                );
            },
        },
        {
            Header: trans('article.reacts'),
            accessor: 'title',
            disableSortBy: true,
            Cell: ({ row }: any) => {
                const { insights, _count } = row.original;
                return (
                    <div className="details">
                        <InfoIcon />
                        <span>{_count?.reaction || 0}</span>
                        <PopDetails reacts={insights} reaction={_count?.reaction} />
                    </div>
                );
            },
        },
        {
            Header: trans('article.shares'),
            accessor: 'isStory',
            disableSortBy: true,
            Cell: ({ row }: any) => {
                const { insights } = row.original;
                return (
                    <div className="details">
                        <InfoIcon />
                        <span>{insights?.shared || 0}</span>
                        <PopDetails isShard={true} shares={insights} />
                    </div>
                );
            },
        },
        {
            Header: trans('article.comments'),
            accessor: 'comments',
            disableSortBy: true,
            Cell: ({ row }: any) => {
                const { insights } = row?.original as any;
                return <div className="comments">{insights?.comment || 0}</div>;
            },
        },
        {
            Header:
                currentTab === 'PENDING'
                    ? trans('g.publish.date.pending')
                    : currentTab === 'REJECTED'
                    ? trans('g.publish.date.rejected')
                    : trans('g.publish.date'),
            accessor: 'updatedAt',
            Cell: ({ row }: any) => {
                const { publishedDate, updatedAt } = row.original;
                return (
                    <div className="date">
                        {format(
                            new Date(currentTab === 'APPROVED' ? publishedDate : updatedAt),
                            'MMM dd, yyyy',
                        )}
                    </div>
                );
            },
        },
        {
            Header: trans('article.pin'),
            accessor: 'pinnedArticle',
            disableSortBy: true,
            Cell: ({ row }: any) => ToggleIsPin({ row }),
        },
        {
            Header: trans('article.notification'),
            accessor: 'notification',
            disableSortBy: true,
            Cell: ({ row }: any) => {
                const { id, title, originContent, storyMedia } = row.original;
                const article = { id, title, content: originContent, storyMedia };

                return (
                    <button
                        className="message_link "
                        style={{ borderColor: 'transparent' }}
                        // routeName="messages"
                        // routeParams={{ id, profileImage, name, email }}
                        onClick={handelnClickNotification.bind(this, article)}
                    >
                        <NotificationsType2 />
                    </button>
                );
            },
        },
        {
            Header: trans('article.message'),
            disableSortBy: true,
            class: 'last_box',
            Cell: ({ row }: any) => {
                const encodedName = encodeURIComponent(row?.original?.publisher?.name);

                return (
                    <Link
                        className="message_link"
                        routeName="messages"
                        routeParams={{
                            id: row?.original?.publisher?.id,
                            profileImage: row?.original?.publisher?.profileImage,
                            name: encodedName,
                            email: row?.original?.publisher?.email,
                        }}
                    >
                        <MessageType2 />
                    </Link>
                );
            },
        },
        {
            Header:
                currentTab === 'REJECTED' || currentTab === 'ARCHIVED'
                    ? trans('article.the.postShow')
                    : trans('article.the.post'),
            disableSortBy: true,
            accessor: 'showArticle',
            class: 'last_box',
            Cell: ({ row }: any) => {
                const { id } = row.original;
                return (
                    <Link className="message_link" routeName="editpost" routeParams={{ id }}>
                        <ArrowType2 />
                    </Link>
                );
            },
        },
    ];

    const msgsEmpty: any = {
        APPROVED: trans('msg.empty.data.articles.approved'),
        PENDING: trans('msg.empty.data.articles.pending'),
        REJECTED: trans('msg.empty.data.articles.rejected'),
        ARCHIVED: trans('msg.empty.data.articles.archived'),
    };

    return (
        <section>
            <HeadTitle title={trans('g.articles')} sub={trans('g.articles')} />

            <Table
                error={error}
                columns={columns}
                data={data}
                titleCount={trans('article.the.articles.count')}
                pagination={pagination}
                isLoading={isLoading}
                getFetch={getListArtciles}
                currentTab={currentTab}
                setCurrentTab={setCurrentTab}
                pendingCount={pendingCount}
                msgEmpty={msgsEmpty[currentTab]}
            />
        </section>
    );
};

// "status":"APPROVED" || "PENDING" || "REJECTED" || "ARCHIVED"
