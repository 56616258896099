import { FC } from 'react';
import { BlockUser } from 'assets';
import { ButtonIcon, Modal } from 'components';
import { SUCCESS_STATUS } from 'constants/auth';
import { useLocales } from 'hooks';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useRouter } from 'react-router5';
import { usersAPI } from 'services/apis';
import { BlockUserProps } from 'types';
import { toast } from 'react-toastify';

export const BlockUserFun: FC<BlockUserProps> = ({ row }) => {
    const [isOpen, setIsOpen] = useState<string>('');
    const [isLoadBtnSave, setIsLoadBtnSave] = useState(false);
    const { id, name, isBlocked } = row.original;
    const { Trans } = useLocales();
    const { navigate } = useRouter();
    const dispatch = useDispatch<any>();

    const handleBLockUser = async () => {
        setIsLoadBtnSave(true);
        const { payload } = await dispatch(
            usersAPI.putBlockUser()({
                userId: id,
                isBlocked: !isBlocked,
            }),
        );
        if (SUCCESS_STATUS.includes(payload?.status)) {
            const { isBlocked, name } = payload?.data.result as any;
            if (isBlocked) {
                navigate('banlist', {
                    typeAction: 'blockUser',
                    name,
                    id,
                });
            }
        } else {
            toast.error(payload?.message?.message);
        }
        setIsLoadBtnSave(false);
    };

    return (
        <>
            <ButtonIcon onClick={() => setIsOpen(id)} icon={BlockUser} size={40} bg="bg2" />
            <Modal
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                onClick={handleBLockUser}
                icon={BlockUser}
                title="g.pop.ban"
                note={
                    <>
                        <Trans i18nKey={'g.pop.ban.note'} /> <strong>{name}</strong> ؟
                    </>
                }
                isLoading={isLoadBtnSave}
                titleSubmit="users.ban"
                typeBtn="red"
            />
        </>
    );
};
