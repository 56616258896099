import { FC } from 'react';
import { HeadTitleProps } from 'types';
import { Container } from './theme';

export const HeadTitle: FC<HeadTitleProps> = ({ title, sub, type }) => {
    return (
        <Container>
            <div>
                <h2>{title}</h2>
                <span>
                    {sub} {type && <span> - {type}</span>}
                </span>
            </div>
        </Container>
    );
};
